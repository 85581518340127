<template>

    <loader :active="loading" message="Please wait..." />

    <div class="common-inner-body customer-list-page-cont">
        <div class="common-inner-cont">
            <div class="common-box">
                <div class="box-top box-top-with-filter-new justify-content-end">
                    <div class="table-top-bar d-flex align-items-center">
                        <div class="top-left">
                            <div class="form-group search-group me-3">
                                <input class="table-search-input" placeholder="Search Here" v-model="searchQuery"
                                    @input="handleSearch" />
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="form-group">
                                <select class="form-select" v-model="selectedPlan">
                                    <option value="Trial">Trial</option>
                                    <option value="Basic">Basic Plan</option>
                                    <option value="Pro">Pro Plan</option>
                                    <option value="Plus">Plus Plan</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-body">
                    <div class="data-table-cont">
                        <div id="example_wrapper" class="dataTables_wrapper">
                            <div class="row dt-row">
                                <div class="col-sm-12">
                                    <div class="table-responsive table-container table-view-serp table-manage-customers"
                                        @scroll="handleScroll">
                                        <table id="example"
                                            class="data-table table table-striped@@@@ table-border-none mb-0">
                                            <thead class="sticky-top">
                                                <tr>
                                                    <th class="no-wrap min-width65">ID</th>
                                                    <th class="no-wrap text-start">Customer Name</th>
                                                    <th class="no-wrap">Current Plan</th>
                                                    <th class="no-wrap text-start">Credits Left</th>
                                                    <th class="no-wrap">Open Profile</th>
                                                    <th class="no-wrap">Switch</th>
                                                    <th class="no-wrap">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="customer in allData" :key="customer.id">
                                                    <td class="no-wrap">{{ customer.id }}</td>
                                                    <td class="no-wrap text-start">{{ customer.name }}</td>
                                                    <td class="no-wrap">{{ customer.plan_name }}</td>
                                                    <td class="no-wrap text-start"><img class="icon-img"
                                                            src="https://serpplus.pluspromotions.co.uk/images/icon-credit-coin.svg"
                                                            alt="Icon Credit"> {{ customer.credits }} Credits</td>
                                                    <td class="no-wrap">
                                                        <!-- <router-link
                                                        :to="'/domain-view/' + project.id"
                                                        class="text-primary"
                                                        >
                                                        Open
                                                        <img
                                                            class="img-fluid"
                                                            src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"
                                                            alt="Open Link Icon"
                                                            title="Open"
                                                        />
                                                        </router-link> -->
                                                        <router-link :to="'/view-customer/' + customer.id"
                                                            class="text-primary">
                                                            Open
                                                            <img class="img-fluid"
                                                                src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"
                                                                alt="Open Link Icon" title="Open" />
                                                        </router-link>
                                                    </td>
                                                    <td class="no-wrap">
                                                        <div class="action-icon-cont"
                                                            style="display: flex; justify-content: center;">
                                                            <a class="table-action-btn-new bg-light-green btn-switch"
                                                                @click="goToUserDashboard(customer.id)">
                                                                <img class="icon-img img-fluid"
                                                                    src="https://serpplus.pluspromotions.co.uk/images/authchange.png"
                                                                    alt="Icon Switch" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td class="no-wrap">
                                                        <div class="action-icon-cont"
                                                            style="display: flex; justify-content: center;">
                                                            <a class="table-action-btn-new bg-signal-red-light btn-remove"
                                                                href="javascript:void(0);"
                                                                @click="deleteCustomer(customer.id)">
                                                                <img class="icon-img img-fluid"
                                                                    src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg"
                                                                    alt="Remove Icon" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="admin-body">
                    <div class="admin-body-box">
                        <div class="admin-body-bottom">
                            <div class="table-top">
                                <input class="table-search-input" placeholder="Search" v-model="searchQuery"
                                    @input="handleSearch" />
                            </div>
                            <div class="data-table-cont">
                                <div class="table-responsive table-container table-manage-customers" @scroll="handleScroll">
                                    <table id="example" class="data-table table table-striped table-border-none mb-0">
                                        <thead class="sticky-top">
                                            <tr>
                                                <th class="no-wrap min-width65">ID</th>
                                                <th class="no-wrap">Name</th>
                                                <th class="no-wrap">Credits</th>
                                                <th class="no-wrap">Plan</th>
                                                <th class="no-wrap">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="customer in allData" :key="customer.id">
                                                <td class="no-wrap">{{ customer.id }}</td>
                                                <td class="no-wrap">{{ customer.name }}</td>
                                                <td class="no-wrap">{{ customer.credits }}</td>
                                                <td class="no-wrap">{{ customer.plan_name }}</td>
                                                <td class="no-wrap">
                                                    <div class="action-icon-cont" style="display: flex; justify-content: center;">
                                                        <router-link :to="'/view-customer/' + customer.id"
                                                            class="action-icon icon-view">
                                                            <img class="icon-img" src="https://serpplus.pluspromotions.co.uk/images/icon-view.svg" alt="View">
                                                        </router-link>
                                                        <a class="action-icon icon-send" @click="goToUserDashboard(customer.id)">
                                                            <img class="icon-img" style="cursor: pointer;"
                                                                src="https://serpplus.pluspromotions.co.uk/images/icon-send.svg" alt="Send">
                                                        </a>
                                                        <a class="action-icon icon-delete" @click="deleteCustomer(customer.id)"
                                                            style="cursor: pointer;">
                                                            <img class="icon-img" src="https://serpplus.pluspromotions.co.uk/images/icon-delete.svg" alt="Delete">
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>

    <CopyRight></copyRight>

</template>


<script setup>
import { ref, onMounted, inject, watch } from 'vue';
import axiosIns from '@/axios';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { ABILITY_TOKEN } from '@casl/vue';
const ability = inject(ABILITY_TOKEN)
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import loader from '../Loader.vue';
import CopyRight from '../copyRight.vue';

const currentPage = ref(1);
const lastPage = ref(1);
const loading = ref(false)
const customers = ref([{}]);
const searchQuery = ref('');
const allData = ref([]);

const selectedPlan = ref('Trial');

const handleScroll = (event) => {
    const container = event.target;

    if (container.scrollHeight > container.clientHeight) {
        if (
            container.scrollTop + container.clientHeight >=
            container.scrollHeight
        ) {
            loadMore();
        }
    }
};

const loadMore = () => {
    if (currentPage.value >= lastPage.value) {
        console.log("No more data to load.");
        return;
    }

    setTimeout(() => {
        currentPage.value++;
        fetchCustomers(currentPage.value);
    }, 1000);
};


async function fetchCustomers(page = 1) {
    loading.value = true;
    try {
        const response = await axiosIns.get(`/customers`, {
            params: {
                page: page,
                search: searchQuery.value,
                plan: selectedPlan.value
            }
        });

        loading.value = false;
        currentPage.value = Number(response.data.current_page);
        lastPage.value = Number(response.data.last_page);
        
        if (page === 1) {
            allData.value = response.data.data;
        } else {
            allData.value.push(...response.data.data);
        }
    } catch (error) {
        console.error('Error fetching Customers:', error);
    }
}


const deleteCustomer = async (id = null) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this customer?');
    if (!confirmDelete) {
        return;
    }

    try {
        await axiosIns.delete(`/user/${id}`);
        customers.value = customers.value.filter((c) => c.id !== id);
        toast.success('Customer Deleted', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    } catch (error) {
        toast.error('Error Deleting Customer', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        console.error('Error Deleting Customer:', error);
    }
};


const goToUserDashboard = async (id) => {
    const response = await axiosIns.get(`auth/change-auth/${id}`);

    const accessToken = response.data.accessToken;
    const userAbilities = response.data.userAbilities;
    const userData = response.data.userData;

    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    localStorage.setItem('userAbilities', JSON.stringify(userAbilities));
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('isAdmin', true);
    toast.success('Login Successfully', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000
    });
    await getUserAbilities();

    window.location.href = '/';
}

async function getUserAbilities() {
    try {
        const abilitiesJson = localStorage.getItem('userAbilities');

        if (abilitiesJson) {
            const abilities = JSON.parse(abilitiesJson);

            const { can, rules } = new AbilityBuilder(createMongoAbility);
            can(abilities);

            ability.update(rules);
        } else {
            console.error('User abilities not found in localStorage');
        }
    } catch (error) {
        console.error('Error retrieving user abilities:', error);
    }
}
const filterData = () => {
    const query = searchQuery.value.toLowerCase();
    allData.value = allData.value.filter((customer) =>
        customer.name.toLowerCase().includes(query)
    );
};

const resetData = async () => {
    currentPage.value = 1;
    allData.value = [];
    await fetchCustomers(currentPage.value);
};

onMounted(async () => {
    await fetchCustomers(currentPage.value);
});
watch(searchQuery, (newQuery) => {
    if (newQuery === '') {
        resetData(); 
    } else {
        filterData(); 
    }
});

watch(selectedPlan, () => {
    fetchCustomers(1);
});


</script>

<style>
.table-container {
    max-height: 2500px;
    overflow-y: auto;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
}
</style>