<template>
    <div class="common-inner-body faq-customer-page-cont">
        <div class="common-inner-cont">

            <div class="header-faq-search-cont">
                <h2 class="top-title">Hello, How can we help?</h2>
                <p class="bottom-text">Stuck on something or getting started? Our friendly team is here to help.</p>
                <div class="top-faq-search">
                    <input class="faq-top-search-input" placeholder="Search Question" v-model="searchQuery">
                </div>
            </div>

            <div class="user-faq-cont px-0 px-xl-100">
                <h2 class="common-section-title">General FAQs</h2>
                <div class="common-nav-tab-cont user-faq-tab-cont">

                    <!-- Mobile Dropdown -->
                    <select v-if="isMobile" class="form-control form-select mb-3" v-model="activeCategory">
                        <option v-for="(category, index) in categories" :key="category.id" :value="index">
                            {{ category.name }}
                        </option>
                    </select>

                    <!-- Desktop Tabs -->
                    <ul v-else class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="(category, index) in categories" :key="category.id">
                            <button class="nav-link" 
                                :class="{ 'active': activeCategory === index }"
                                @click="activeCategory = index; activeQuestion = null"
                                :id="'question-' + category.id + '-tab'"
                                data-bs-toggle="tab"
                                :data-bs-target="'#question-' + category.id"
                                type="button" role="tab"
                                :aria-controls="'question-' + category.id"
                                :aria-selected="activeCategory === index">
                                {{ category.name }}
                            </button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" :class="{ 'show active': activeCategory === index }"
                            v-for="(category, index) in categories" :key="category.id" :id="'question-' + category.id"
                            role="tabpanel" :aria-labelledby="'question-' + category.id + '-tab'">
                            <div class="top-question-cont d-flex align-items-center mb-30">
                                <img class="q-icon" :src="icons[index]" alt="Icon User">
                                <span class="t-cont">
                                    <h2 class="title-q">{{ category.name }}</h2>
                                </span>
                            </div>
                            <div class="user-faq-div">
                                <div class="common-accordian accordion" id="accordionExample">
                                    <div class="accordion-item" v-for="(question, qIndex) in category.questions" :key="question.id">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button" type="button"
                                                @click="toggleQuestion(qIndex)"
                                                :aria-expanded="activeQuestion === qIndex"
                                                :aria-controls="'collapse-' + category.id + '-' + qIndex"
                                                v-html="question.highlighted || question.question">
                                            </button>
                                        </h2>
                                        <div :id="'collapse-' + category.id + '-' + qIndex"
                                            class="accordion-collapse collapse"
                                            :class="{ 'show': activeQuestion === qIndex }"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">{{ question.answer }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>  

    <copyRight></copyRight>

</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import copyRight from '../copyRight';
import axiosIns from '@/axios';

// Reactive Variables
const categories = ref([]);
const searchQuery = ref('');
const icons = ref([
    'https://serpplus.pluspromotions.co.uk/images/icon-1.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-2.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-3.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-4.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-5.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-6.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-7.png'
]);
const activeCategory = ref(0);
const activeQuestion = ref(null); // Track the active question
const isMobile = ref(window.innerWidth <= 767); // Check screen size

// Toggle logic for the accordion questions
const toggleQuestion = (qIndex) => {
    activeQuestion.value = activeQuestion.value === qIndex ? null : qIndex;
};

// Update `isMobile` on window resize
const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 767;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);

    axiosIns.get('category-faq')
        .then(response => {
            categories.value = response.data;
        })
        .catch(error => {
            console.error('Error:', error.message);
        });
});

watch(searchQuery, async (newVal, oldVal) => {
    if (newVal !== oldVal) {
        const filteredCategories = categories.value.filter(category => {
            return category.questions.some(question => 
                question.question.toLowerCase().includes(newVal.toLowerCase())
            );
        });

        if (searchQuery.value === '') {
            try {
                const response = await axiosIns.get('category-faq');
                categories.value = response.data;
                activeCategory.value = 0;
                activeQuestion.value = null;
            } catch (error) {
                console.error('Error fetching categories:', error.message);
            }
        } else {
            const filteredCategoriesWithMatchedQuestions = filteredCategories.map(category => {
                return {
                    ...category,
                    questions: category.questions.map(question => {
                        return {
                            ...question,
                            highlighted: question.question.replace(
                                new RegExp(`(${newVal})`, "gi"),
                                `<mark>$1</mark>`
                            )
                        };
                    })
                };
            });

            if (filteredCategoriesWithMatchedQuestions.length > 0) {
                categories.value = filteredCategoriesWithMatchedQuestions;
                activeCategory.value = 0;
                activeQuestion.value = null;
            }
        }
    }
});
</script>

