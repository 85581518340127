<template>

    <loader :active="loading" message="Please wait..." />

  
    <div class="common-inner-body customer-list-page-cont">
        <div class="common-inner-cont">
            <div class="common-box">
                <div class="table-top">
                    <div class="header-top-btn-cont">
                        <a class="admin-common-btn d-inline-flex gap-10 align-items-center mb-30" @click="addCategory" data-bs-toggle="modal" data-bs-target="#modal-add-category"><img class="icon-img" src="https://serpplus.pluspromotions.co.uk/images/icon-plus.svg" alt="Icon Plus"> Add New Category</a>
                    </div>
                </div>                
                <div class="data-table-cont">
                    <div class="table-responsive table-container table-view-serp table-manage-category">
                        <table id="example" class="data-table table table-border-none mb-0">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap text-start">Name</th>
                                    <th class="no-wrap text-center width10">Edit Name</th>
                                    <th class="no-wrap text-center width10">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in categoryFaq" :key="index">
                                    <td class="no-wrap text-start">{{ item.name }}</td>
                                    <td class="no-wrap text-center">
                                        <div class="action-icon-cont">
                                            <a class="table-action-btn-new bg-yellow-light-two icon-edit" @click="editCategory(item)"
                                                data-bs-toggle="modal" data-bs-target="#modal-edit-category">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-edit-faq-cat.svg" alt="Edit">
                                            </a>
                                        </div>
                                    </td>
                                    <td class="no-wrap text-center">
                                        <div class="action-icon-cont">
                                            <a class="table-action-btn-new bg-signal-red-light btn-remove" @click="deleteCategory(item)">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon">
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL - ADD CATEGORY - START -->
    <div id="modal-add-category" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title flex-column">
                            <span class="mod-title">Add Category</span> 
                            <p class="modal-sub-title">Add Your New Category</p>
                        </h5>
                        <a class="btn-close"  data-bs-dismiss="modal" aria-label="Close">
                            <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Icon Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Category Name:</label>
                                <input class="form-control" type="text" placeholder="Enter Name" v-model="name" required>
                            </div>
                            <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
                        </div>
                    </div>
                    <div class="modal-footer col-gap-20 row-gap-10">
                        <Button class="admin-common-btn btn-light-red btn-reset" type="reset">Reset</Button>
                        <Button class="admin-common-btn btn btn-primary btn-save" type="button" data-bs-dismiss="modal" @click="saveCategory">Save Category</Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD CATEGORY - END -->

    <!-- MODAL - EDIT CATEGORY - START -->
    <div id="modal-edit-category" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title flex-column">
                            <span class="mod-title">Edit Category</span>
                            <p class="modal-sub-title">Update Category Information</p>
                        </h5>
                        <a class="btn-close"  data-bs-dismiss="modal" aria-label="Close">
                            <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Icon Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Category Name:</label>
                                <input class="form-control" type="text" placeholder="Enter Name" v-model="name"
                                    required>
                            </div>
                            <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
                        </div>
                    </div>
                    <div class="modal-footer col-gap-20 row-gap-10">
                        <Button class="admin-common-btn btn-light-red btn-reset" type="reset">Reset</Button>
                        <Button class="admin-common-btn btn btn-primary btn-save-changes" type="button" data-bs-dismiss="modal" @click="updateCategory">Save Changes</Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - EDIT CATEGORY - END -->

    <CopyRight></copyRight>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAbility } from '@casl/vue';
import CopyRight from '../copyRight.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import loader from '../Loader.vue';

const { can } = useAbility();
const categoryFaq = ref([]);
const categoryId = ref(null);
const name = ref('');
const loading = ref(false)

const saveCategory = async () => {
    try {
        await axiosIns.post('category-faq', { name: name.value });

        toast.success('Category Created!', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });

        fetchCategory()
    } catch (error) {
        console.error('Error:', error.message);
    }
};
const updateCategory = async () => {
    try {
        await axiosIns.put(`/category-faq/${categoryId.value}`, { name: name.value });

        toast.success('Category Updated!', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });

        fetchCategory()
    } catch (error) {
        console.error('Error:', error.message);
    }
};

const editCategory = async (data) => {
    categoryId.value = data.id;
    name.value = data.name
}
const addCategory = async () => {
    name.value = ''
}

const deleteCategory = async (category) => {
    try {
        const confirmed = confirm("Are you sure you want to delete this category?");

        if (confirmed) {
            const categoryId = category.id;

            await axiosIns.delete(`/category-faq/${categoryId}`);

            categoryFaq.value = categoryFaq.value.filter((r) => r.id !== categoryId);

            toast.success('Category Deleted!', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
        }
    } catch (error) {
        console.error('Error deleting category:', error.message);
    }
};


const fetchCategory = async () => {
    loading.value = true
    try {
        const data = await axiosIns.get('category-faq');
        loading.value = false
        categoryFaq.value = data.data;
    } catch (error) {
        console.error('Error:', error.message);
    }

}

onMounted(async () => {
    fetchCategory()
});


</script>