<template>

    <div class="common-inner-body page-name-page-cont">
        <div class="common-inner-cont">


            <!-- <hr class="typography-devider"> -->

            <!-- Dashboard Page HTML - START -->            
            <h2 class="typography-page-title mt-0">Dashboard Page</h2>

            <div class="dashboard-container">

                <div class="dashboard-quick-ststistics mb-30">
                    <h2 class="dashboard-title d-flex flex-wrap align-items-center col-gap-10"><img class="img-fluid" src="http://localhost:8080/images/icon-quick-ststistics.svg" alt="Icon Quick Ststistics"> Quick Statistics</h2>
                    <div class="dashboard-cards-container">

                        <h5 class="text-center bg-danger text-white w-100 p-2">For Admin</h5>

                        <div class="dashboard-card bg-one">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card1.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Total Revenue</div>
                                <!-- <div class="dashboard-card-value">{{ dashboardData?.totalRevenue?.totalRevenue }} {{ dashboardData?.totalRevenue?.currency }}</div> -->
                                <div class="dashboard-card-value">989 GBP</div>
                            </div>
                        </div>
                        <div class="dashboard-card bg-two">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card2.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Total Number of Users</div>
                                <!-- <div class="dashboard-card-value">{{ dashboardData?.totalUsers }}</div> -->
                                <div class="dashboard-card-value">1041</div>
                            </div>
                        </div>
                        <div class="dashboard-card bg-three">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card3.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of Trial Users</div>
                                <!-- <div class="dashboard-card-value">{{ dashboardData?.trialUsers }}</div> -->
                                <div class="dashboard-card-value">356</div>
                            </div>
                        </div>
                        <div class="dashboard-card bg-four">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card4.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of Paid Users</div>
                                <!-- <div class="dashboard-card-value">{{ dashboardData?.paidUsers }}</div> -->
                                <div class="dashboard-card-value">685</div>
                            </div>
                        </div>
                        <div class="dashboard-card bg-five">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card5.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of Active Users</div>
                                <!-- <div class="dashboard-card-value">{{ dashboardData?.activeUsers }}</div> -->
                                <div class="dashboard-card-value">983</div>
                            </div>
                        </div>
                        <div class="dashboard-card bg-six">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card6.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Inactive Users</div>
                                <!-- <div class="dashboard-card-value">{{ dashboardData?.inactiveUsers }}</div> -->
                                <div class="dashboard-card-value">58</div>
                            </div>
                        </div>
                        <div class="dashboard-card bg-seven">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card7.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Domain Projects</div>
                                <!-- <div class="dashboard-card-value">
                                    {{ dashboardData?.today?.todaySerps }} <span class="info">Today</span> {{ dashboardData?.total?.totalSerpsCount }} <span class="info">Total</span>
                                </div> -->
                                <div class="dashboard-card-value">
                                    20 <span class="info">Today</span> 350 <span class="info">Total</span>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-card bg-eight">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="http://localhost:8080/images/icon-dashboard-card8.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of SERPS</div>
                                <!-- <div class="dashboard-card-value">
                                    {{ dashboardData?.today?.todaySerps }} <span class="info">Today</span> {{ dashboardData?.total?.totalSerpsCount }} <span class="info">Total</span>
                                </div> -->
                                <div class="dashboard-card-value">
                                    723 <span class="info">Today</span> 4242 <span class="info">Total</span>
                                </div>
                            </div>
                        </div>

                        <h5 class="text-center bg-danger text-white w-100 p-2">For Customer</h5>

                        <div class="dashboard-card bg-one">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card1.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Left Credits</div>
                                <!-- <div class="dashboard-card-value">{{ dashboardData?.userData?.leftCredits }}</div> -->
                                <div class="dashboard-card-value">1000</div>
                            </div>
                        </div>                      

                        <div class="dashboard-card bg-three">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card4.svg" alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">User Plan</div>
                                <div class="dashboard-card-value">
                                    <!-- <p class="w-100 mb-0">{{ planName || 'Free Plan' }}</p> -->
                                    <p class="w-100 mb-0">Free Plan</p>
                                    <!-- <span class="info" v-if="planName && remainingDays !== null">
                                        Remaining Days: {{ remainingDays > 0 ? remainingDays : 'Expired' }}
                                    </span> -->
                                    <span class="info">
                                        Remaining Days: 60
                                    </span>
                                    <div class="clearfix w-100"></div>
                                    <!-- <span class="info" v-else>
                                        No active subscription.
                                    </span> -->
                                    <span class="info">
                                        No active subscription.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="dashboard-credits-use">
                    <h2 class="dashboard-title d-flex flex-wrap align-items-center col-gap-10"><img class="img-fluid" src="http://localhost:8080/images/icon-credit-use.svg" alt="Icon Credits Use"> Credits Use</h2>
                    <!-- Chart with Radio Buttons -->
                    <div class="chart-container ">
                        <div class="filter-container">
                            <div class="filter-label">Daily Credits Usage By Customers</div>
                            <div class="form-check-group-inline">
                                <div class="form-group mb-0">
                                    <div class="custom-form-check-new form-check-inline">
                                        <input type="radio" class="form-check-input" form-check-input value="weekly" />
                                        <label class="form-check-label" for="checkboxWeekly">Weekly</label>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="custom-form-check-new form-check-inline">
                                        <input type="radio" class="form-check-input" value="monthly" checked />
                                        <label class="form-check-label" for="checkboxMonthly">Monthly</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <apexchart type="area" height="350" :options="chartOptions" :series="chartSeries" /> -->
                        <img class="img-fluid w-100" src="http://localhost:8080/images/chart-img.jpg" alt="Chart Image" />
                    </div>

                </div>

            </div>
            <!-- Dashboard Page HTML - END -->


            <!-- Create Serp Page HTML - START -->
            <h2 class="typography-page-title">Create Serp Page</h2>

            <div class="create-serp-search">
                <h2 class="top-title">Check Your Serp Easily</h2>
                <h2 class="top-subtitle">
                    Enter Your Details Below to Check the Latest Numbers for
                    <br>
                    <strong>Keyword Ranking and more</strong>
                </h2>
                <form class="serp-search-form-cont">
                    <div>
                        <div class="serp-search-box">
                            <div
                                class="search-fld-row first-row d-flex flex-wrap justify-content-center col-gap-20 max-w-100">
                                <div class="search-fld-cont your-domain">
                                    <div class="input-fld-cont">
                                        <!-- <input class="form-control" :class="{ active: isActive || formData.domainUrl.trim() !== '' }" type="text" placeholder="Your Domain" v-model="formData.domainUrl" @input="validateDomain" @focus="isActive = true" @blur="handleBlur" /> -->
                                        <input class="form-control active" type="text" placeholder="Your Domain" />
                                        <!-- <span v-if="domainValidationError" class="error-message">Please enter a valid domain. Example: 'google.com', 'amazon.com', or 'example.co.uk'.".</span> -->
                                        <span class="error-message">Please enter a valid domain.</span>
                                    </div>
                                </div>
                                <div class="search-fld-cont search-enging">
                                    <div class="input-fld-cont">
                                        <!-- <select class="form-control"
                                            :class="{ active: isSearchEngineActive || formData.searchEngine !== '' }"
                                            v-model="formData.searchEngine" :disabled="userData?.type == 1"
                                            @change="validateSearchEngine" @focus="isSearchEngineActive = true"
                                            @blur="handleSearchEngineBlur">
                                            <option value="">Select Search Engine</option>
                                            <option v-for="engine in searchEngine" :key="engine.id" :value="engine.id">
                                                {{ engine.name }}
                                            </option>
                                        </select> -->
                                        <select class="form-control active@@@@">
                                            <option value="">Select Search Engine</option>
                                            <option>Google</option>
                                            <option>Yahoo</option>
                                            <option>Bing</option>
                                        </select>
                                        <!-- <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more options</span> -->
                                        <!-- <span v-if="searchEngineError" class="error-message">Please select a search engine.</span> -->
                                        <span class="error-message">Select a search engine.</span>
                                    </div>
                                </div>
                                <div class="search-fld-cont search-device">
                                    <div class="input-fld-cont">
                                        <!-- <select class="form-control"
                                            :class="{ active: isDeviceTypeActive || formData.deviceType !== '' }"
                                            v-model="formData.deviceType" :disabled="userData?.type == 1"
                                            @change="validateDeviceType" @focus="isDeviceTypeActive = true"
                                            @blur="handleDeviceTypeBlur">
                                            <option value="">Select Device</option>
                                            <option v-for="device in deviceType" :key="device.id" :value="device.id">
                                                {{ device.name }}
                                            </option>
                                        </select> -->
                                        <select class="form-control active@@@@">
                                            <option value="">Select Device</option>
                                            <option>Desktop</option>
                                            <option>Tablet</option>
                                            <option>Mobile</option>
                                        </select>
                                        <!-- <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more options</span> -->
                                        <!-- <span v-if="deviceTypeError" class="error-message">Please select a device.</span> -->
                                        <span class="error-message">Select a device.</span>
                                    </div>
                                </div>
                                <div class="search-fld-cont search-language">
                                    <div class="input-fld-cont">
                                        <!-- <select class="form-control"
                                            :class="{ active: isLanguageActive || formData.language !== '' }"
                                            v-model="formData.language" :disabled="userData?.type == 1"
                                            @change="validateLanguage" @focus="isLanguageActive = true"
                                            @blur="handleLanguageBlur">
                                            <option value="">Select Language</option>
                                            <option v-for="language in languages" :key="language.code"
                                                :value="language.code">
                                                {{ language.name }}
                                            </option>
                                        </select> -->
                                        <select class="form-control active@@@@">
                                            <option value="">Select Language</option>
                                            <option>English</option>
                                            <option>Language 2</option>
                                            <option>Language 3</option>
                                            <option>Language 4</option>
                                        </select>
                                        <!-- <span class="required-alert" v-show="userData?.type == 1">Upgrade to view more options</span> -->
                                        <!-- <span v-if="languageError" class="error-message">Please select a language.</span> -->
                                        <span class="error-message">Select a language.</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="search-fld-row second-row d-flex flex-wrap justify-content-center col-gap-20 max-w-100">
                                <div class="search-fld-cont search-country">
                                    <div class="input-fld-cont">
                                        <!-- <select class="form-control"
                                            :class="{ active: isCountryActive || formData.country !== '' }"
                                            v-model="formData.country"
                                            @change="onCountryChange"
                                            @focus="isCountryActive = true"
                                            @blur="handleCountryBlur"
                                            @keydown.down.prevent="navigateDropdown(1)"
                                            @keydown.up.prevent="navigateDropdown(-1)"
                                            @keydown.enter.prevent="selectDropdownItem"
                                            >
                                            <option value="">Select a Country</option>
                                            <option v-for="(country, index) in regionList"
                                                :key="country.country_iso_code" :value="country"
                                                :class="{ 'dropdown-active': index === dropdownIndex }">
                                                {{ country.location_name }}
                                            </option>
                                        </select> -->
                                        <select class="form-control active@@@@">
                                            <option value="">Select a Country</option>
                                            <option>Country 1</option>
                                            <option>Country 2</option>
                                            <option>Country 3</option>
                                            <option>Country 4</option>
                                        </select>
                                        <!-- <span v-if="countryError" class="error-message">Please select a country.</span> -->
                                        <span class="error-message">Please select a country.</span>
                                    </div>
                                </div>
                                <div class="search-fld-cont search-state-city">
                                    <div class="input-fld-cont">
                                        <!-- <input class="form-control" type="text" v-model="searchQuery" @input="onChange" :disabled="!formData.country"  placeholder="Enter State and City optional" @keydown.down.prevent="navigateResults(1)" @keydown.up.prevent="navigateResults(-1)" @keydown.enter.prevent="selectResult(searchResults[resultIndex])" /> -->
                                        <input class="form-control active@@@@" type="text" placeholder="Enter State and City optional" />
                                        <!-- <ul v-show="showResults" class="autocomplete-results">
                                            <li v-for="(result, index) in searchResults" :key="index" class="autocomplete-result" :class="{ 'result-active': index === resultIndex }" @click="selectResult(result)" @mouseover="hoverResult(index)">{{ result.location_name }}</li>
                                            <li v-if="searchResults.length === 0 && searchQuery.trim() !== ''" class="autocomplete-no-results">No results found</li>
                                        </ul> -->
                                        <!-- <ul class="autocomplete-results">
                                            <li class="autocomplete-result result-active">Location 1</li>
                                            <li class="autocomplete-no-results">No results found</li>
                                        </ul> -->
                                    </div>
                                </div>
                            </div>
                            <div
                                class="search-fld-row third-row d-flex flex-wrap justify-content-center col-gap-20 max-w-100">
                                <div class="search-fld-cont search-tag-box">
                                    <label>Keywords</label>
                                    <div class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                        <!-- <input v-model="newKeyword" class="form-control" type="text" placeholder="Enter your keywords for search" @keydown.enter.prevent="addKeyword" /> -->
                                        <input class="form-control" type="text" placeholder="Enter your keywords for search" />
                                        <a class="fld-add-icon" href="javascript:void(0);">
                                            <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-search-add.svg" alt="Icon Reset" />
                                        </a>
                                    </div>
                                    <!-- <span v-if="showKeywordError" class="error-message">Keywords cannot be empty.</span> -->
                                    <span class="error-message">Keywords cannot be empty.</span>
                                    <div class="input-fld-cont search-tag-cont">
                                        <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                            <!-- <li v-for="(keyword, index) in formData.keyword" :key="index" class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">{{ keyword }}</span>
                                                <button @click="removeKeyword(index)" class="remove-btn" aria-label="Remove competitor">✕</button>
                                            </li> -->
                                            <li class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">Keyword 1</span>
                                                <button class="remove-btn" aria-label="Remove competitor">✕</button>
                                            </li>
                                            <li class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">Keyword 2</span>
                                                <button class="remove-btn" aria-label="Remove competitor">✕</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="search-fld-cont search-tag-box" :class="{ 'disabled-section': userData?.type == 1 }"> -->
                                <div class="search-fld-cont search-tag-box">
                                    <label>Competitors</label>
                                    <div class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                        <!-- <input v-model="newCompetitor" class="form-control" type="text" placeholder="Enter domains, Max 3 domains" :disabled="userData?.type == 1" @keydown.enter.prevent="addCompetitor" /> -->
                                        <input class="form-control" type="text" placeholder="Enter domains, Max 3 domains" />
                                        <!-- <a class="fld-add-icon" href="#" @click.prevent="addCompetitor">
                                            <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-search-add.svg" alt="Icon Reset" />
                                        </a> -->
                                        <a class="fld-add-icon" href="javascript:void(0);">
                                            <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-search-add.svg" alt="Icon Reset" />
                                        </a>
                                    </div>
                                    <!-- <span v-if="competitorError" class="error-message">{{ competitorErrorMessage }}</span> -->
                                    <span class="error-message">{{ competitorErrorMessage }}</span>
                                    <!-- <span v-if="userData?.type == 1" class="error-message">Upgrade to view more options</span> -->
                                    <span class="error-message">Upgrade to view more options</span>
                                    <div class="input-fld-cont search-tag-cont">
                                        <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                            <!-- <li v-for="(competitor, index) in formData.competitor" :key="index" class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">{{ competitor }}</span>
                                                <button @click="userData?.type != 1 && removeCompetitor(index)" class="remove-btn" aria-label="Remove competitor">✕</button>
                                            </li> -->
                                            <li class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">Competitor 1</span>
                                                <button class="remove-btn" aria-label="Remove competitor">✕</button>
                                            </li>
                                            <li class="competitor-item d-flex align-items-center">
                                                <span class="competitor-name">Competitor 2</span>
                                                <button class="remove-btn" aria-label="Remove competitor">✕</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="search-fld-row btn-row d-flex flex-wrap justify-content-center col-gap-20 row-gap-10 max-w-100">
                                <div class="search-fld-cont btn-cont mb-0">
                                    <button class="admin-common-btn btn-reset" type="button" @click="resetConfirm">
                                        <span><img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-search-reset.svg" alt="Icon Reset" /> Reset
                                        </span>
                                    </button>
                                </div>
                                <div class="search-fld-cont btn-cont mb-0">
                                    <button class="admin-common-btn btn-ranking" type="submit"
                                        :disabled="isSubmitDisabled">
                                        <span>
                                            <img class="img-fluid"
                                                src="https://serpplus.pluspromotions.co.uk/images/icon-search-ranking.svg"
                                                alt="Icon Ranking" />
                                            Ranking
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <!-- <div v-if="formData.suggestions" class="suggestion-box">
                                <p>💡 {{ formData.suggestions }}</p>
                            </div>
                            <p v-if="isSubmitDisabled" style="color: red !important;" class="text-muted mt-2">
                                Please fill above all the inputs to enable the button.
                            </p> -->
                        </div>
                    </div>
                </form>
                <h2 class="bottom-title">Get Real-Time SERP Results and Effortlessly<br>Track Your Keyword Rankings for
                    Better SEO Performance</h2>
                <h2 class="bottom-subtitle">Stay ahead in SEO with Serp Plus! Get instant access to the latest SERP
                    results and track your keyword rankings easily. Our simple dashboard provides real-time insights to
                    help you improve your SEO and beat the competition</h2>
                <div class="search-page-img-cont">
                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/create-serp-page-bg.png"
                        alt="Create Serp Page BG" />
                </div>
            </div>
            <!-- Create Serp Page HTML - END -->


            <!-- SERP Listing Page HTML - START -->
            <h2 class="typography-page-title">SERP Listing Page</h2>

            <div class="view-serp-page-cont" style="border-top: 1.5px solid #ECEDF1;">
                <div class="view-serp-table-cont">
                    <div class="view-serp-table-box">
                        <div class="box-top box-top-with-filter-new justify-content-between">
                            <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                                <router-link to="/create-serp" class="btn btn-light admin-common-btn me-4"><img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg" alt="Check Serp Icon" />Check Serp</router-link>
                                <p class="info-p mb-0">Total Search: <span>10 <!--{{ total }}--></span></p>
                                <p class="info-p mb-0">Total Keywords: <span>86 <!--{{ totalKeywords }}--></span></p>
                            </div>
                            <div class="table-top-bar">
                                <div class="top-left">
                                    <div class="form-group search-group">
                                        <!-- <input class="table-search-input" placeholder="Search Here" v-model="searchQuery" @input="serpsList(1, true)" /> -->
                                        <input class="table-search-input" placeholder="Search Here" />
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="data-table-cont">
                                <div id="example_wrapper" class="dataTables_wrapper">
                                    <div class="row dt-row">
                                        <div class="col-sm-12">
                                            <div class="table-responsive table-container table-view-serp" @scroll="handleScroll">
                                                <table id="example" class="data-table table table-border-none table-max-h1500">
                                                    <thead class="sticky-top">
                                                        <tr>
                                                            <th class="no-wrap"></th>
                                                            <th class="no-wrap">ID</th>
                                                            <th class="no-wrap text-start">Domain</th>
                                                            <th class="no-wrap text-start">Location</th>
                                                            <th class="no-wrap text-start">Keywords</th>
                                                            <th class="no-wrap text-start">Device</th>
                                                            <th class="no-wrap text-start">Search Engine</th>
                                                            <th class="no-wrap text-start">Date</th>
                                                            <th class="no-wrap">Refresh</th>
                                                            <th class="no-wrap">Export</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <!-- <template v-for="(item, index) in allData"> -->
                                                            <!-- <tr :class="{ 'odd': index % 2 === 0, 'even': index % 2 !== 0, 'highlight': index === 0 && highlighted, 'has-child': selectedRow === index }"> -->
                                                            <tr class="odd has-child">
                                                                <td class="no-wrap">
                                                                    <!-- <a class="toggleChild table-toggle-btn" :class="{ 'active': selectedRow === index }" @click="toggleItem(index)" title="Toggle"> -->
                                                                    <a class="toggleChild table-toggle-btn active" title="Toggle">
                                                                        <img class="icon-arrow" src="https://serpplus.pluspromotions.co.uk/images/icon-table-toggle-arrow.svg" alt="Icon Table Toggle Arrow" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap">
                                                                    01 
                                                                    <!--{{ item.id }}-->
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    www.freepik.com
                                                                    <!--{{ item.url ?? 'N/A' }}-->
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    England
                                                                    <!--{{ item.location ?? 'N/A' }}-->
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    8
                                                                    <!--{{ item.keywords_count }}-->
                                                                </td>
                                                                <td class="no-wrap text-start">Desktop</td>
                                                                <td class="no-wrap text-start">Google</td>
                                                                <td class="no-wrap text-start responsive-wrap">
                                                                    06/18/2024
                                                                    <!-- {{ item.date.split('-').reverse().join('-') }} <br> {{ item.time }} -->
                                                                </td>
                                                                <td class="no-wrap text-center">
                                                                    <!-- <a class="refresh-icon-cont" @click="refreshItem(item)" title="Refresh"> -->
                                                                    <a class="refresh-icon-cont" title="Refresh">
                                                                        <img class="icon-refresh" src="https://serpplus.pluspromotions.co.uk/images/icon-refresh-data-table-new.svg" alt="Refresh Icon" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap text-center action-buttons" style="white-space: nowrap;">
                                                                    <!-- <a class="excel-icon-cont" @click="exportToExcel(item)" title="Export to Excel"> -->
                                                                    <a class="excel-icon-cont" title="Export to Excel">
                                                                        <img class="icon-excel" src="https://serpplus.pluspromotions.co.uk/images/icon-excel-new.svg" alt="Excel Export Icon" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <!-- <tr v-if="selectedRow === index"> -->
                                                            <tr>
                                                                <!-- <td class="td-additional-info" :colspan="10"> -->
                                                                <td class="td-additional-info" colspan="10">
                                                                    <div class="additional-info-cont">
                                                                        <div class="info-body">
                                                                            <table class="table-additional-info table table-border-none">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th class="text-start">Keyword</th>
                                                                                        <th class="text-start">Ranked Url</th>
                                                                                        <th class="text-start">Your Rank</th>
                                                                                        <!-- <th class="text-start text-primary-new" v-if="item.items[0].competitor1?.name"> -->
                                                                                        <th class="text-start text-primary-new">
                                                                                            www.freepik.com
                                                                                            <!-- {{ item.items[0].competitor1.name }} -->
                                                                                        </th>
                                                                                        <!-- <th class="text-start text-primary-new" v-if="item.items[0].competitor2?.name"> -->
                                                                                        <th class="text-start text-primary-new">
                                                                                            rugs.ie
                                                                                            <!-- {{ item.items[0].competitor2.name }} -->
                                                                                        </th>
                                                                                        <!-- <th class="text-start text-primary-new" v-if="item.items[0].competitor3?.name"> -->
                                                                                        <th class="text-start text-primary-new">
                                                                                            therugloft.ie
                                                                                            <!-- {{ item.items[0].competitor3.name }} -->
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <!-- <tr v-for="(keywordItem, keywordIndex) in item.items" :key="'keyword-' + keywordIndex" :class="{ 'odd': keywordIndex % 2 === 0, 'even': keywordIndex % 2 !== 0 }"> -->
                                                                                    <tr class="odd">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                            <!-- {{ keywordItem.keyword }} -->
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <!-- <a class="keyword-item-url" :href="keywordItem.url" target="_blank"> -->
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">
                                                                                                https://rugshop.ie/handcrafted-irish-rug-classic-design
                                                                                                <!-- {{ keywordItem.url || 'N/A' }} -->
                                                                                            </a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <!-- <span class="rank">{{ keywordItem.rank }}</span> -->
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <!-- <td class="text-start" v-if="keywordItem.competitor1?.name"> -->
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                                <!-- {{ keywordItem.competitor1.rank || 'N/A' }} -->
                                                                                            </span>
                                                                                        </td>
                                                                                        <!-- <td class="text-start" v-if="keywordItem.competitor2?.name"> -->
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                                <!-- {{ keywordItem.competitor2.rank || 'N/A' }} -->
                                                                                            </span>
                                                                                        </td>
                                                                                        <!-- <td class="text-start" v-if="keywordItem.competitor3?.name"> -->
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                                <!-- {{ keywordItem.competitor3.rank || 'N/A' }} -->
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr class="even">
                                                                                        <td class="text-start no-wrap">rugshop Ireland</td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">05</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">09</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">22</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="even">
                                                                <td class="no-wrap">
                                                                    <a class="toggleChild table-toggle-btn" title="Toggle">
                                                                        <img class="icon-arrow" src="https://serpplus.pluspromotions.co.uk/images/icon-table-toggle-arrow.svg" alt="Icon Table Toggle Arrow" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap">
                                                                    02
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    www.freepik.com
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    England
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    8
                                                                </td>
                                                                <td class="no-wrap text-start">Desktop</td>
                                                                <td class="no-wrap text-start">Google</td>
                                                                <td class="no-wrap text-start responsive-wrap">
                                                                    06/18/2024
                                                                </td>
                                                                <td class="no-wrap text-center">
                                                                    <a class="refresh-icon-cont" title="Refresh">
                                                                        <img class="icon-refresh" src="https://serpplus.pluspromotions.co.uk/images/icon-refresh-data-table-new.svg" alt="Refresh Icon" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap text-center action-buttons" style="white-space: nowrap;">
                                                                    <a class="excel-icon-cont" title="Export to Excel">
                                                                        <img class="icon-excel" src="https://serpplus.pluspromotions.co.uk/images/icon-excel-new.svg" alt="Excel Export Icon" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="td-additional-info" colspan="10">
                                                                    <div class="additional-info-cont">
                                                                        <div class="info-body">
                                                                            <table class="table-additional-info table table-border-none">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th class="text-start">Keyword</th>
                                                                                        <th class="text-start">Ranked Url</th>
                                                                                        <th class="text-start">Your Rank</th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            www.freepik.com
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            rugs.ie
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            therugloft.ie
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="odd">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr class="even">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="even">
                                                                <td class="no-wrap">
                                                                    <a class="toggleChild table-toggle-btn" title="Toggle">
                                                                        <img class="icon-arrow" src="https://serpplus.pluspromotions.co.uk/images/icon-table-toggle-arrow.svg" alt="Icon Table Toggle Arrow" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap">
                                                                    02
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    www.freepik.com
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    England
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    8
                                                                </td>
                                                                <td class="no-wrap text-start">Desktop</td>
                                                                <td class="no-wrap text-start">Google</td>
                                                                <td class="no-wrap text-start responsive-wrap">
                                                                    06/18/2024
                                                                </td>
                                                                <td class="no-wrap text-center">
                                                                    <a class="refresh-icon-cont" title="Refresh">
                                                                        <img class="icon-refresh" src="https://serpplus.pluspromotions.co.uk/images/icon-refresh-data-table-new.svg" alt="Refresh Icon" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap text-center action-buttons" style="white-space: nowrap;">
                                                                    <a class="excel-icon-cont" title="Export to Excel">
                                                                        <img class="icon-excel" src="https://serpplus.pluspromotions.co.uk/images/icon-excel-new.svg" alt="Excel Export Icon" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="td-additional-info" colspan="10">
                                                                    <div class="additional-info-cont">
                                                                        <div class="info-body">
                                                                            <table class="table-additional-info table table-border-none">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th class="text-start">Keyword</th>
                                                                                        <th class="text-start">Ranked Url</th>
                                                                                        <th class="text-start">Your Rank</th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            www.freepik.com
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            rugs.ie
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            therugloft.ie
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="odd">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr class="even">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="even">
                                                                <td class="no-wrap">
                                                                    <a class="toggleChild table-toggle-btn" title="Toggle">
                                                                        <img class="icon-arrow" src="https://serpplus.pluspromotions.co.uk/images/icon-table-toggle-arrow.svg" alt="Icon Table Toggle Arrow" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap">
                                                                    02
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    www.freepik.com
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    England
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    8
                                                                </td>
                                                                <td class="no-wrap text-start">Desktop</td>
                                                                <td class="no-wrap text-start">Google</td>
                                                                <td class="no-wrap text-start responsive-wrap">
                                                                    06/18/2024
                                                                </td>
                                                                <td class="no-wrap text-center">
                                                                    <a class="refresh-icon-cont" title="Refresh">
                                                                        <img class="icon-refresh" src="https://serpplus.pluspromotions.co.uk/images/icon-refresh-data-table-new.svg" alt="Refresh Icon" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap text-center action-buttons" style="white-space: nowrap;">
                                                                    <a class="excel-icon-cont" title="Export to Excel">
                                                                        <img class="icon-excel" src="https://serpplus.pluspromotions.co.uk/images/icon-excel-new.svg" alt="Excel Export Icon" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="td-additional-info" colspan="10">
                                                                    <div class="additional-info-cont">
                                                                        <div class="info-body">
                                                                            <table class="table-additional-info table table-border-none">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th class="text-start">Keyword</th>
                                                                                        <th class="text-start">Ranked Url</th>
                                                                                        <th class="text-start">Your Rank</th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            www.freepik.com
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            rugs.ie
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            therugloft.ie
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="odd">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr class="even">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr class="even">
                                                                <td class="no-wrap">
                                                                    <a class="toggleChild table-toggle-btn" title="Toggle">
                                                                        <img class="icon-arrow" src="https://serpplus.pluspromotions.co.uk/images/icon-table-toggle-arrow.svg" alt="Icon Table Toggle Arrow" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap">
                                                                    02
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    www.freepik.com
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    England
                                                                </td>
                                                                <td class="no-wrap text-start">
                                                                    8
                                                                </td>
                                                                <td class="no-wrap text-start">Desktop</td>
                                                                <td class="no-wrap text-start">Google</td>
                                                                <td class="no-wrap text-start responsive-wrap">
                                                                    06/18/2024
                                                                </td>
                                                                <td class="no-wrap text-center">
                                                                    <a class="refresh-icon-cont" title="Refresh">
                                                                        <img class="icon-refresh" src="https://serpplus.pluspromotions.co.uk/images/icon-refresh-data-table-new.svg" alt="Refresh Icon" />
                                                                    </a>
                                                                </td>
                                                                <td class="no-wrap text-center action-buttons" style="white-space: nowrap;">
                                                                    <a class="excel-icon-cont" title="Export to Excel">
                                                                        <img class="icon-excel" src="https://serpplus.pluspromotions.co.uk/images/icon-excel-new.svg" alt="Excel Export Icon" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="td-additional-info" colspan="10">
                                                                    <div class="additional-info-cont">
                                                                        <div class="info-body">
                                                                            <table class="table-additional-info table table-border-none">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th class="text-start">Keyword</th>
                                                                                        <th class="text-start">Ranked Url</th>
                                                                                        <th class="text-start">Your Rank</th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            www.freepik.com
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            rugs.ie
                                                                                        </th>
                                                                                        <th class="text-start text-primary-new">
                                                                                            therugloft.ie
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="odd">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr class="even">
                                                                                        <td class="text-start no-wrap">
                                                                                            rugshop Ireland
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <a class="keyword-item-url" href="https://rugshop.ie/handcrafted-irish-rug-classic-design" target="_blank">https://rugshop.ie/handcrafted-irish-rug-classic-design</a>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">11</span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                05
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                09
                                                                                            </span>
                                                                                        </td>
                                                                                        <td class="text-start">
                                                                                            <span class="rank">
                                                                                                22
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        <!-- </template> -->
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SERP Listing Page HTML - END -->



            <!-- Domain Projects / Domain Rank Checker Page HTML - START -->
            <h2 class="typography-page-title">Domain Projects / Domain Rank Checker Page</h2>

            <h5 class="text-center bg-danger text-white w-100 p-2">For User</h5>

            <div class="domain-projects-page-cont" style="border-top: 1.5px solid #ECEDF1; margin-bottom: 60px; min-height: unset;">
                <div class="domain-projects-table-cont">
                    <div class="domain-projects-table-box">
                        <div class="box-top box-top-with-filter-new justify-content-between">
                            <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                            <router-link to="/domain-check" class="btn btn-light admin-common-btn me-4">
                                <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg"
                                alt="Check Serp Icon" />
                                Check Domain Rank
                            </router-link>
                            </div>
                            <div class="table-top-bar">
                                <div class="top-left">
                                    <div class="form-group search-group">
                                        <!-- <input class="table-search-input" placeholder="Search Here" v-model="searchTerm" @input="fetchProjects" /> -->
                                        <input class="table-search-input" placeholder="Search Here" />
                                    </div>
                                </div>
                                <div class="top-right">
                                    <div class="form-group btn-group">
                                        <button class="admin-common-btn">Search</button>
                                    </div>
                                    <div class="form-group btn-group">
                                        <button class="btn btn-light filter-btn export-btn">
                                            <img class="flag-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-excel-export.svg" alt="Icon Excel Export">Export
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="data-table-cont">
                                <!-- <div class="table-responsive table-container table-normal-view table-domain-projects" @scroll="handleScroll"> -->
                                <div class="table-responsive table-container table-normal-view table-domain-projects">
                                    <table id="example3" class="data-table table table-competitor-view dataTable no-footer table-max-h1500">
                                        <thead class="sticky-top">
                                            <tr>
                                                <th class="no-wrap align-left">
                                                    <div class="th-short-box">
                                                        <div class="txt-icon-cont">
                                                            <span class="th-txt">Domain</span>
                                                            <img class="img-fluid th-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle.svg" alt="Question Icon" title="Domain" />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="no-wrap align-left">
                                                    <div class="th-short-box">
                                                        <div class="txt-icon-cont">
                                                            <span class="th-txt">Location</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="no-wrap align-left">
                                                    <div class="th-short-box">
                                                        <div class="txt-icon-cont">
                                                            <span class="th-txt">Limit</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="no-wrap align-left">
                                                    <div class="th-short-box">
                                                        <div class="txt-icon-cont">
                                                            <span class="th-txt">Date Created</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="no-wrap align-center">
                                                    <div class="th-short-box">
                                                        <div class="txt-icon-cont">
                                                            <span class="th-txt">Open Project</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th class="no-wrap align-center" style="width: 67px">
                                                    <div class="th-short-box">
                                                        <div class="txt-icon-cont">
                                                            <span class="th-txt">Remove</span>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- <tr v-for="project in allData" :key="project.id" class="no-wrap"> -->
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <!-- <router-link :to="'/domain-view/' + project.id">{{ project.target }}</router-link> -->
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    <!-- {{ project.location_name }} -->
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    <!-- {{ project.limit }} -->
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    <!-- {{ project.created_at }} -->
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <!-- <router-link :to="'/domain-view/' + project.id" class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link> -->
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <!-- <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#" @click="deleteProject(project)"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a> -->
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>
                                            <tr class="no-wrap">
                                                <td class="align-left">
                                                    <router-link>ethoswatches.com</router-link>
                                                </td>
                                                <td class="align-left">
                                                    Ireland
                                                </td>
                                                <td class="align-left">
                                                    1000                                                    
                                                </td>
                                                <td class="align-left">
                                                    2025-02-24 18:56:01
                                                </td>
                                                <td class="align-center">
                                                    <p class="text-highlight">
                                                        <router-link class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" /></router-link>
                                                    </p>
                                                </td>
                                                <td class="align-center">
                                                    <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"><img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                                                </td>
                                            </tr>

                                            <!-- <tr v-if="loading"> -->
                                            <tr>
                                                <td colspan="10" class="text-center">
                                                    <p style="font-size: 1.2em">⏳ Loading...</p>
                                                </td>
                                            </tr>

                                            <!-- <tr v-else-if="!allData.length"> -->
                                            <tr>
                                                <td colspan="10" class="text-center">
                                                    <p style="font-size: 1.2em">🧐 No Data Available</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="text-center bg-danger text-white w-100 p-2">For User If Data Not Available</h5>

            <div class="common-top-cont text-center mb-60">
                <div class="width900 d-inline-block">
                <h1 class="common-top-title fs-40 fw-normal text-yellow-two mb-15">Domain Checker Module  is Locked!</h1>
                <p class="common-top-p fs-20 lh-sm text-light-new mb-30">You don't have any active plan. Please upgrade to Analyse domain performance with detailed keyword rankings, traffic insights, and comparison reports.</p>
                <a class="admin-common-btn admin-common-btn-new-big yellow-two" href="#"><img class="img-fluid mr-10" src="http://localhost:8080/images/icon-crown.svg" alt="Icon Crown" /> Upgrade Now & Unlock Fun Access!</a>
                </div>
            </div>

            <div class="common-text-img-box-cont">

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                        <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Keyword & Traffic Insights</h2>
                        <p class="box-p fs-16 mb-20">Gain valuable insights into your website’s performance with comprehensive keyword and traffic data:</p>
                        <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Number of Ranking Keywords:</strong> Track how many keywords your website ranks for in search engines.</li>
                            <li><strong class="text-dark-new fw-medium">Monthly Traffic Estimation:</strong> Get an accurate estimate of your organic and paid traffic to understand audience reach and engagement.</li>
                        </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                        <div class="img-box">
                            <img class="img-fluid w-100" src="http://localhost:8080/images/text-img-box-image1.jpg" alt="Box Image" />
                        </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                        <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Performance Charts</h2>
                        <p class="box-p fs-16 mb-20">Visualize your website’s success with intuitive graphs and charts:</p>
                        <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Top Pages Analysis:</strong>  See which pages drive the most traffic and contribute to your website’s growth.</li>
                            <li><strong class="text-dark-new fw-medium">Paid vs. Organic Traffic:</strong> Compare traffic sources to refine your SEO and advertising strategies effectively.</li>
                        </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                        <div class="img-box">
                            <img class="img-fluid w-100" src="http://localhost:8080/images/text-img-box-image2.jpg" alt="Box Image" />
                        </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                        <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Detailed Data Table</h2>
                        <p class="box-p fs-16 mb-20">Access a structured, in-depth view of your website’s keyword rankings and performance metrics:</p>
                        <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Domain Position Tracking:</strong> Monitor your website’s position for targeted keywords over time.</li>
                            <li><strong class="text-dark-new fw-medium">Keyword Insights:</strong> Get key details like search volume, keyword difficulty (KD), cost-per-click (CPC), last update date, and creation date to strategize better.</li>
                        </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                        <div class="img-box">
                            <img class="img-fluid w-100" src="http://localhost:8080/images/text-img-box-image3.jpg" alt="Box Image" />
                        </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                        <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Comparison Reports</h2>
                        <p class="box-p fs-16 mb-20">Evaluate progress with historical ranking comparisons:</p>
                        <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Today vs. Earlier Rankings:</strong> Analyze how your keyword rankings have changed over time, helping you track SEO improvements or drops.</li>
                        </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                        <div class="img-box">
                            <img class="img-fluid w-100" src="http://localhost:8080/images/text-img-box-image4.jpg" alt="Box Image" />
                        </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                        <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Data Export</h2>
                        <p class="box-p fs-16 mb-20">Seamlessly export data for further analysis and offline access:</p>
                        <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Excel Download:</strong> Download keyword insights, rankings, and traffic reports in an easy-to-use Excel format for detailed review and sharing.</li>
                        </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                        <div class="img-box">
                            <img class="img-fluid w-100" src="http://localhost:8080/images/text-img-box-image5.jpg" alt="Box Image" />
                        </div>
                        </div>
                    </div>
                </div>

                <div class="upgrade-message-container upgrade-big w-100">
                    <h2 class="upgrade-title">Purchase A Plan To Unlock This Feature</h2>
                    <p class="upgrade-text text-light-new">You don't have any active plan. Please purchase a plan to take your SEO game to the next level!</p>
                    <div class="upgrade-btn-cont">
                        <router-link to='/pricing-page' class="upgrade-link admin-common-btn admin-common-btn-new-big yellow-two"><img class="img-fluid mr-10" src="http://localhost:8080/images/icon-crown.svg" alt="Icon Crown" /> View Planes</router-link>
                    </div>
                </div>

            </div>
            <!-- Domain Projects / Domain Rank Checker Page HTML - END -->


            <!-- Serp Project List Page HTML - START -->
            <h2 class="typography-page-title">Serp Project List Page</h2>

            <h5 class="text-center bg-danger text-white w-100 p-2">For User</h5>

            <!-- <div class="backlink-table-box" v-if="userData?.type === 2"> -->
            <div class="backlink-table-cont" style="margin: 30px 0;">
                <div class="backlink-table-box">
                    <div class="box-top box-top-with-filter-new justify-content-between">
                        <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                            <router-link to="/create-rank-project" class="btn btn-light admin-common-btn me-4">
                                <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg" alt="Check Serp Icon" />Check Serp Project
                            </router-link>
                        </div>
                        <div class="table-top-bar">
                            <div class="top-left">
                                <div class="form-group search-group">
                                    <!-- <input class="table-search-input" placeholder="Search Here" v-model="searchQueryProjects" /> -->
                                    <input class="table-search-input" placeholder="Search Here" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-body">
                        <div class="data-table-cont">
                            <!-- <div class="table-responsive table-container table-normal-view table-backlink" @scroll="handleScroll"> -->
                            <div class="table-responsive table-container table-normal-view table-backlink">
                            <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                                <thead>
                                    <tr>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                                <span class="th-txt">Project Name</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Domain</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Location</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Keywords</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Device</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Search Engine</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Date Created</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Last Run</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap align-center">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Run Time</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap align-center">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Open Project</span>
                                            </div>
                                        </div>
                                        </th>
                                        <th class="no-wrap align-center">
                                        <div class="th-short-box">
                                            <div class="txt-icon-cont">
                                            <span class="th-txt">Remove</span>
                                            </div>
                                        </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr v-for="project in allData" :key="project.id"> -->
                                    <tr>
                                        <td class="align-left no-wrap">
                                            <!-- {{ project.projectName || "N/A" }} -->
                                            Best Rado Watch's
                                        </td>
                                        <td class="align-left no-wrap">
                                            <!-- {{ project.domain || "N/A" }} -->
                                            rado.com
                                        </td>
                                        <td class="align-left no-wrap">
                                            <!-- {{ project.location || "N/A" }} -->
                                            Mumbai, Maharashtra, India
                                        </td>
                                        <td class="align-left no-wrap">
                                            <!-- {{ project.keyword_count ? project.keyword_count : 0 }} -->
                                            10
                                        </td>
                                        <td class="align-left no-wrap">
                                            <!-- {{ deviceType.find( (item) => item.id === project.deviceType )?.name || "Unknown Device" }} -->
                                            Desktop
                                        </td>
                                        <td class="align-left no-wrap">
                                            <!-- {{ searchEngine.find( (item) => item.id === project.searchEngine )?.name || "Unknown Search Engine" }} -->
                                            Google
                                        </td>
                                        <td class="align-left no-wrap">
                                            <!-- {{ project.date_created || "N/A" }} -->
                                            2025-02-24 18:43:39
                                        </td>
                                        <td class="align-left no-wrap">
                                            <!-- {{ project.last_executed_at || "N/A" }} -->
                                            2025-02-26 00:03:23
                                        </td>
                                        <td class="align-center">
                                            <!-- <a class="table-clock-icon-bordered" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-run-time" @click="openRunTimeModal(project)"> -->
                                            <a class="table-clock-icon-bordered" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-run-time">
                                                <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-clock-new.svg"
                                                alt="Clock Icon" />
                                            </a>
                                        </td>

                                        <td class="align-center">
                                            <p class="text-highlight">
                                                <!-- <RouterLink :to="`/view-serp-project/${project.id}`" class="text-primary">Open<img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"alt="Open Link Icon" title="Open" /></RouterLink> -->
                                                <RouterLink class="text-primary">Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"alt="Open Link Icon" title="Open" /></RouterLink>
                                            </p>
                                        </td>
                                        <td class="align-center">
                                            <!-- <a class="table-action-btn-new bg-signal-red-light btn-remove" href="javascript:void(0);" title="Remove" @click.prevent="confirmDelete(project.id)"> -->
                                            <a class="table-action-btn-new bg-signal-red-light btn-remove" href="javascript:void(0);" title="Remove">
                                                <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" />
                                            </a>
                                        </td>
                                    </tr>

                                    <!-- <tr v-if="isLoading"> -->
                                    <tr>
                                        <td colspan="10" class="text-center">
                                            <p style="font-size: 1.2em">⏳ Loading...</p>
                                        </td>
                                    </tr>

                                    <!-- <tr v-else-if="!allData.length"> -->
                                    <tr>
                                        <td colspan="10" class="text-center">
                                            <p style="font-size: 1.2em">🧐 No Data Available</p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="text-center bg-danger text-white w-100 p-2">For User If Data Not Available</h5>

            <div class="common-top-cont text-center mb-60">
                <div class="width900 d-inline-block">
                    <h1 class="common-top-title fs-40 fw-normal text-yellow-two mb-15">SERP Projects Module is Locked!</h1>
                    <p class="common-top-p fs-20 lh-sm text-light-new mb-30">You don't have any active plan. Please upgrade to
                    track and analyse search engine ranking performance with advanced automation and competitor comparison
                    features.</p>
                    <a class="admin-common-btn admin-common-btn-new-big yellow-two" href="#"><img class="img-fluid mr-10"
                        src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> Upgrade Now &
                    Unlock Fun Access!</a>
                </div>
            </div>

            <div class="common-text-img-box-cont">

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Automated Ranking Schedules</h2>
                            <p class="box-p fs-16 mb-20">Stay on top of your search performance with scheduled ranking checks:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Customizable Frequency:</strong> Automate keyword ranking
                                checks on a daily, weekly, or monthly basis to track trends effortlessly.</li>
                            <li><strong class="text-dark-new fw-medium">Hands-Free Monitoring:</strong> Eliminate manual tracking
                                and get regular updates on your rankings without any extra effort.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                            <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image6.jpg"
                                alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Competitor Analysis</h2>
                            <p class="box-p fs-16 mb-20">Gain a competitive edge by comparing your rankings with industry rivals:
                            </p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Competitor Benchmarking:</strong> Track your website’s
                                performance against competitors and identify gaps in your SEO strategy.</li>
                            <li><strong class="text-dark-new fw-medium">Position Tracking:</strong> Monitor ranking shifts and
                                spot opportunities to outrank competitors in search results.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                            <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image7.jpg"
                                alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Search Volume & Keyword Difficulty
                            Management</h2>
                            <p class="box-p fs-16 mb-20">Make informed SEO decisions with detailed keyword metrics:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Search Volume Tracking:</strong> Understand how often a
                                keyword is searched, helping you focus on high-impact opportunities.</li>
                            <li><strong class="text-dark-new fw-medium">Keyword Difficulty (KD) Analysis:</strong> Evaluate how
                                challenging it is to rank for a keyword and prioritize efforts accordingly.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                            <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image8.jpg"
                                alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Download Reports</h2>
                            <p class="box-p fs-16 mb-20">Easily access and analyze your ranking data with exportable reports:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                            <li><strong class="text-dark-new fw-medium">Excel Format Exports:</strong> Download keyword rankings,
                                competitor insights, and traffic data in Excel format for in-depth analysis and reporting.</li>
                            <li><strong class="text-dark-new fw-medium">Data-Driven Decisions:</strong> Use offline reports to
                                refine your SEO strategy, share insights with your team, and measure progress over time.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                            <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image9.jpg"
                                alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="upgrade-message-container upgrade-big w-100">
                    <h2 class="upgrade-title">Purchase A Plan To Unlock This Feature</h2>
                    <p class="upgrade-text text-light-new">You don't have any active plan. Please purchase a plan to take your
                    SEO game to the next level!</p>
                    <div class="upgrade-btn-cont">
                    <router-link to='/pricing-page'
                        class="upgrade-link admin-common-btn admin-common-btn-new-big yellow-two"><img class="img-fluid mr-10"
                        src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> View
                        Planes</router-link>
                    </div>
                </div>

            </div>
            <!-- Serp Project List Page HTML - END -->


            <!-- Backlinks Project Page HTML - START -->
            <h2 class="typography-page-title">Backlinks Project Page Page</h2>

            <div class="common-top-cont text-center mb-60">
                <div class="width900 d-inline-block">
                    <h1 class="common-top-title fs-40 fw-normal text-yellow-two mb-15">Backlink Checker Module is Locked!</h1>
                    <p class="common-top-p fs-20 lh-sm text-light-new mb-30">You don't have any active plan. Please upgrade to Comprehensive backlink analysis with detailed insights on domains, links, and rankings.</p>
                    <a class="admin-common-btn admin-common-btn-new-big yellow-two" href="#"><img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> Upgrade Now & Unlock Fun Access!</a>
                </div>
            </div>

            <div class="common-text-img-box-cont">

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Backlink Overview</h2>
                            <p class="box-p fs-16 mb-20">Get a complete picture of your website’s backlink profile to strengthen your SEO strategy:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                                <li><strong class="text-dark-new fw-medium">Total Backlinks Count:</strong> Track the total number of backlinks pointing to your website.</li>
                                <li><strong class="text-dark-new fw-medium">Broken Backlinks Count:</strong> Identify and fix broken links that could harm your SEO performance.</li>
                                <li><strong class="text-dark-new fw-medium">External & Internal Links Count:</strong> Analyze both inbound and outbound link structures for better optimization.</li>
                                <li><strong class="text-dark-new fw-medium">Referring Domains Count:</strong> See how many unique domains are linking to your website.</li>
                                <li><strong class="text-dark-new fw-medium">Crawled Pages Analysis:</strong> Understand which pages have the most backlinks and how search engines are crawling them.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                                <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image10.jpg" alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Referring Domains Breakdown</h2>
                            <p class="box-p fs-16 mb-20">Gain deeper insights into your referring domains and their impact:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                                <li><strong class="text-dark-new fw-medium">Total Referring Domains:</strong> See how many unique domains are linking to your site.</li>
                                <li><strong class="text-dark-new fw-medium">Nofollow Referring Domains:</strong> Identify links that do not pass SEO value and analyze their impact.</li>
                                <li><strong class="text-dark-new fw-medium">Main Referring Domains:</strong> Discover the most influential domains linking to your website.</li>
                                <li><strong class="text-dark-new fw-medium">Nofollow Main Domains:</strong> Track which top referring domains use nofollow attributes on their links.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                                <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image11.jpg" alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Detailed Chart Analysis</h2>
                            <p class="box-p fs-16 mb-20">Visualize backlink data for better decision-making:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                                <li><strong class="text-dark-new fw-medium">Referring Links by Type:</strong> Breakdown of link types, including redirects, anchor links, image links, canonical links, and more.</li>
                                <li><strong class="text-dark-new fw-medium">Links Distribution by TLD:</strong> See how backlinks are spread across .com, .org, .net, and other domain extensions.</li>
                                <li><strong class="text-dark-new fw-medium">Backlinks Overview & Links by Country:</strong> Identify backlink sources and analyze country-wise distribution.</li>
                                <li><strong class="text-dark-new fw-medium">New vs. Lost Domains Analysis:</strong> Track how your backlink profile is evolving over time.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                                <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image12.jpg" alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Main Data Table</h2>
                            <p class="box-p fs-16 mb-20">Access a structured, detailed table with key backlink metrics:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                                <li><strong class="text-dark-new fw-medium">Page Titles with Associated Links:</strong> View the exact pages linking to your content.</li>
                                <li><strong class="text-dark-new fw-medium">Domain Rank & Page Rank:</strong> Evaluate the authority of linking domains and pages.</li>
                                <li><strong class="text-dark-new fw-medium">External & Internal Links Count:</strong> See how links are distributed across your website.</li>
                                <li><strong class="text-dark-new fw-medium">Anchor Text Analysis:</strong> Understand the context of links with detailed anchor text insights.</li>
                                <li><strong class="text-dark-new fw-medium">First Seen & Last Seen Timestamps:</strong> Track when a backlink was first detected and its latest status.</li>
                                <li><strong class="text-dark-new fw-medium">Active Links Tracking:</strong> Monitor which backlinks are still active and which have been removed.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                                <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image13.jpg" alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="common-text-img-box">
                    <div class="common-text-img-row row row-30">
                        <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Export Reports</h2>
                            <p class="box-p fs-16 mb-20">Easily download and analyze backlink insights:</p>
                            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                                <li><strong class="text-dark-new fw-medium">Comprehensive Excel Exports:</strong> Download detailed reports on backlinks, referring domains, and traffic analysis for further study and offline access.</li>
                                <li><strong class="text-dark-new fw-medium">Actionable Insights:</strong> Use exported data to refine your backlink strategy, detect lost links, and improve your link-building efforts.</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-5 img-col col-30">
                            <div class="img-box">
                                <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image14.jpg" alt="Box Image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="upgrade-message-container upgrade-big w-100">
                    <h2 class="upgrade-title">Purchase A Plan To Unlock This Feature</h2>
                    <p class="upgrade-text text-light-new">You don't have any active plan. Please purchase a plan to take your SEO game to the next level!</p>
                    <div class="upgrade-btn-cont">
                        <router-link to='/pricing-page' class="upgrade-link admin-common-btn admin-common-btn-new-big yellow-two"><img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> View Planes</router-link>
                    </div>
                </div>

            </div>
            <!-- Backlinks Project Page HTML - END --> 
            

        </div>
    </div>

    <copyRight></copyRight>

</template>

<script>
import CopyRight from '../copyRight.vue';

export default {
    components: {
        CopyRight
    },
};
</script>

<style>
.typography-page-title {
    background-color: #FFF4E7;
    border-top: 10px solid var(--color-yellow-two);
    border-right: 10px solid var(--color-yellow-two);
    border-bottom: 10px solid var(--Main-Primary);
    border-left: 10px solid var(--Main-Primary);
    font-size: 20px;
    font-weight: bold;
    margin: 100px 0 50px 0;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
}
.typography-devider {
    border-radius: 10px;
    border-top: 10px solid var(--color-yellow-two);
    border-bottom: 10px solid var(--Main-Primary);
    margin: 100px 0;
    opacity: 1;
}
</style>