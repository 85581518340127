<template>

    <div class="common-inner-body manage-account-page-cont">
        <div class="common-inner-cont">
            <div class="account-main-cont row row-gap-30">
                <div class="account-left-cont col-12 col-lg-6 col-xl-5 col-xxl-4">
                    <div class="profile-info-cont">
                        <div
                            class="profile-info-box d-flex flex-column align-items-center common-border-new border-radius15 position-relative p-30 pt-60 mb-30">
                            <span
                                class="profile-picture d-flex justify-content-center align-items-center rounded-circle p-10 mb-20">
                                {{ user.name ? user.name.charAt(0).toUpperCase() : 'N' }}

                            </span>
                            <h2 class="customer-name fs-30 fw-semibold text-dark-new mb-10">
                                {{ user.name }}
                            </h2>
                            <span class="customer-type bg-body-color p-10 border-radius5 text-light-new mb-10"> {{user?.role?.[0] || "No Role Assigned" }}

                            </span>
                            <h3 className="customer-type-title fs-20 fw-semibold text-yellow-two mb-20"></h3>

                            <div class="profile-info-boxes-cont row-gap-20 mb-20">
                                <div class="info-box d-flex align-items-center col-gap-10">
                                    <div class="info-box-left bg-yellow-light border-radius10 p-10">
                                        <img class="icon-profile-info img-fluid d-block ratio-1x1 w42"
                                            src="https://serpplus.pluspromotions.co.uk/images/icon-profile-credit-yellow.svg"
                                            alt="Icon Profile Credit">
                                    </div>
                                    <div class="info-box-right">
                                        <p class="info-number text-yellow-two fs-30 lh-1 mb-0">
                                            {{ user.credits }}
                                        </p>
                                        <p class="info-text text-light-new fs-20 lh-1 mb-0">Credits Left</p>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-details-box-cont w-100">
                                <h5 class="title-profile-details text-primary-new fs-20 mb-20">Details</h5>
                                <hr class="border-one mt-0 mb-15 opacity-100">
                                <p class="p-profile-detail mb-10" >
                                    <strong>Phone Number: </strong>
                                    <a class="text-light-new text-decoration-none" href="tel:+91 987654321" style="padding-left: 2px !important;">{{
                                        user.contact || "Not Provided" }}</a>
                                </p>
                                <p class="p-profile-detail mb-10" >
                                    <strong>Email: </strong>
                                    <a class="text-light-new text-decoration-none" style="padding-left: 2px;"
                                        href="mailto:rajiv.bajajcrescentek@gmail.com">
                                        {{ user.email || "Not Provided" }}
                                    </a>
                                </p>
                                <p class="p-profile-detail mb-10">
                                    <strong>Country: </strong>
                                    <span class="text-light-new"> {{ user.userCountry || "Not Provided" }}</span>
                                </p>
                                <p class="p-profile-detail mb-10">
                                    <strong>Languages: </strong>
                                    <span class="text-light-new">{{ user.userLanguage || "Not Provided" }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-right-cont d-flex flex-column col-12 col-lg-6 col-xl-7 col-xxl-8">
                    <div class="box-top box-top-with-filter-new justify-content-between">
                        <ul class="nav nav-tabs border-0 col-gap-10 row-gap-10" id="myTab" role="tablist">
                            <li class="nav-item w-xs-100" role="presentation" v-for="(tab, index) in tabs" :key="index">
                                <button
                                    :class="['nav-link', 'btn', 'btn-light', 'admin-common-btn', 'bg-transparent', 'text-dark-new', 'border-0', 'w-100', { active: activeTab === tab }]"
                                    @click="activeTab = tab">
                                    <img class="img-fluid mr-10" :src="icons[index]" :alt="`${tab} Icon`">{{ tab }}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div v-if="activeTab == 'Edit Profile Info'"
                        class="profile-info-box common-border-new border-radius15 position-relative p-30 pt-20">
                        <div class="info-box-cont">
                            <h2 class="info-box-title">Edit Profile</h2>
                            <p class="info-box-subtext">Update your personal information</p>
                           
                                <div class="profile-info-form-cont">
                                    <div class="row custom-row-10">
                                        <div class="col-12 col-xxl-6 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>Full Name</label>
                                                <input class="form-control" v-model="user.name" type="text"
                                                    placeholder="John Doe">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xxl-6 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>Email Address</label>
                                                <input class="form-control" type="email" v-model="user.email"
                                                    placeholder="john.doe@example.com">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xxl-4 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>Phone Number</label>
                                                <input class="form-control" type="text" v-model="user.contact"
                                                    placeholder="+1234567890">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xxl-4 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>Country</label>
                                                <select class="form-control" v-model="user.country">
                                                    <option>Select a Country</option>
                                                    <option v-for="country in regionList"
                                                        :key="country.country_iso_code" :value="country.location_code">
                                                        {{ country.location_name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-xxl-4 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>Language</label>
                                                <select class="form-control" v-model="user.language">
                                                    <option v-for="language in languages" :key="language.code"
                                                        :value="language.code">
                                                        {{ language.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="bottom-btn-cont d-flex flex-wrap justify-content-start col-gap-20 row-gap-20 mt-10">
                                        <button class="admin-common-btn btn-light-red btn-cancel"
                                            type="button">Cancel</button>
                                        <button class="admin-common-btn btn btn-primary btn-update-info" 
                                            :loading="isLoading" @click="updateUser">Update info</button>
                                    </div>
                                </div>
                           
                        </div>
                    </div>
                    <div v-if="activeTab === 'Security'"
                        class="profile-info-box common-border-new border-radius15 position-relative p-30 pt-20">
                        <div class="info-box-cont">
                            <h2 class="info-box-title">Change Password</h2>
                            <p class="info-box-subtext">Update your personal information</p>
                            <div class="common-alert alert alert-warning alert-dismissible fade show" role="alert">
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                    <img class="img-fluid"
                                        src="https://serpplus.pluspromotions.co.uk/images/icon-alert-close-yellow.svg"
                                        alt="Icon Alert Close">
                                </button>
                                <h4 class="fs-20 fw-semibold text-yellow-two mb-0">Ensure that these requirements are
                                    met</h4>
                                <p class="text-light-new mb-0">Minimum 8 characters long, uppercase & symbol</p>
                            </div>
                         
                                <div class="profile-info-form-cont">
                                    <div class="row custom-row-10">
                                        <div class="col-12 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>Enter Your Current Password</label>
                                                <input v-model="oldPassword" class="form-control" type="password"
                                                placeholder="Enter Old Password" required>
                                            </div>
                                        </div>
                                        <div class="col-12 col-xxl-6 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>New Password</label>
                                                <input v-model="newPassword" class="form-control" type="password"
                                    placeholder="Enter New Password" required>
                                            </div>
                                        </div>
                                        <div class="col-12 col-xxl-6 custom-col-10">
                                            <div class="form-group form-group-new">
                                                <label>Confirm New Password</label>
                                                <input v-model="confirmPassword" class="form-control" type="password"
                                                placeholder="Confirm New Password" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="bottom-btn-cont d-flex flex-wrap justify-content-start col-gap-20 row-gap-20 mt-10">
                                        <button class="admin-common-btn btn-light-red btn-cancel"
                                            type="button">Cancel</button>
                                        <button class="admin-common-btn btn btn-primary btn-change-password"
                                            :loading="isLoading" @click="updateUser">Change
                                            Password</button>
                                    </div>
                                  
                                </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <CopyRight></copyRight>

   


</template>

<script setup>
import { ref, onMounted , onBeforeMount } from 'vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import CountryService from '@/service/CountryService';
import axiosIns from '@/axios';
import CopyRight from '../copyRight.vue';

const tabs = ["Edit Profile Info", "Security"];
const activeTab = ref("Edit Profile Info");

const icons = [
    "https://serpplus.pluspromotions.co.uk/images/icon-edit-profile-info.svg",
    "https://serpplus.pluspromotions.co.uk/images/icon-security.svg"
];

const countryService = new CountryService();
const user = ref({});
const isLoading = ref(false);
const errorMsg = ref(null);
const regionList = ref([]); 
const oldPassword = ref(null);
const newPassword = ref(null);
const confirmPassword = ref(null);
const languages = [
    { code: 'af', name: 'Afrikaans' },
    { code: 'sq', name: 'Albanian' },
    { code: 'am', name: 'Amharic' },
    { code: 'ar', name: 'Arabic' },
    { code: 'hy', name: 'Armenian' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'eu', name: 'Basque' },
    { code: 'be', name: 'Belarusian' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'ca', name: 'Catalan' },
    { code: 'ceb', name: 'Cebuano' },
    { code: 'ny', name: 'Chichewa' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'co', name: 'Corsican' },
    { code: 'hr', name: 'Croatian' },
    { code: 'cs', name: 'Czech' },
    { code: 'da', name: 'Danish' },
    { code: 'nl', name: 'Dutch' },
    { code: 'en', name: 'English' },
    { code: 'eo', name: 'Esperanto' },
    { code: 'et', name: 'Estonian' },
    { code: 'tl', name: 'Filipino' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fr', name: 'French' },
    { code: 'fy', name: 'Frisian' },
    { code: 'gl', name: 'Galician' },
    { code: 'ka', name: 'Georgian' },
    { code: 'de', name: 'German' },
    { code: 'el', name: 'Greek' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'ht', name: 'Haitian Creole' },
    { code: 'ha', name: 'Hausa' },
    { code: 'haw', name: 'Hawaiian' },
    { code: 'iw', name: 'Hebrew' },
    { code: 'hi', name: 'Hindi' },
    { code: 'hmn', name: 'Hmong' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'ig', name: 'Igbo' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ga', name: 'Irish' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'jw', name: 'Javanese' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'km', name: 'Khmer' },
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'ko', name: 'Korean' },
    { code: 'ku', name: 'Kurdish (Kurmanji)' },
    { code: 'ky', name: 'Kyrgyz' },
    { code: 'lo', name: 'Lao' },
    { code: 'la', name: 'Latin' },
    { code: 'lv', name: 'Latvian' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lb', name: 'Luxembourgish' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'mg', name: 'Malagasy' },
    { code: 'ms', name: 'Malay' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mt', name: 'Maltese' },
    { code: 'mi', name: 'Maori' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'my', name: 'Myanmar (Burmese)' },
    { code: 'ne', name: 'Nepali' },
    { code: 'no', name: 'Norwegian' },
    { code: 'or', name: 'Odia (Oriya)' },
    { code: 'ps', name: 'Pashto' },
    { code: 'fa', name: 'Persian' },
    { code: 'pl', name: 'Polish' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ru', name: 'Russian' },
    { code: 'sm', name: 'Samoan' },
    { code: 'gd', name: 'Scots Gaelic' },
    { code: 'sr', name: 'Serbian' },
    { code: 'st', name: 'Sesotho' },
    { code: 'sn', name: 'Shona' },
    { code: 'sd', name: 'Sindhi' },
    { code: 'si', name: 'Sinhala' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sl', name: 'Slovenian' },
    { code: 'so', name: 'Somali' },
    { code: 'es', name: 'Spanish' },
    { code: 'su', name: 'Sundanese' },
    { code: 'sw', name: 'Swahili' },
    { code: 'sv', name: 'Swedish' },
    { code: 'tg', name: 'Tajik' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'th', name: 'Thai' },
    { code: 'tr', name: 'Turkish' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'ug', name: 'Uyghur' },
    { code: 'uz', name: 'Uzbek' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'cy', name: 'Welsh' },
    { code: 'xh', name: 'Xhosa' },
    { code: 'yi', name: 'Yiddish' },
    { code: 'yo', name: 'Yoruba' },
    { code: 'zu', name: 'Zulu' }
];
const updateUser = async () => {
    
    if (newPassword.value !== null && confirmPassword.value !== null && newPassword.value !== confirmPassword.value) {
        alert("New password and confirm password do not match.");
        isLoading.value = false;
        return false;
    }

    isLoading.value = true;
    const payload = {
        name: user.value.name,
        email: user.value.email,
        country: user.value.country,
        contact: user.value.contact,
        oldPassword: oldPassword.value,
        password: newPassword.value,
        language: user.value.language,
    };

    try {
        await axiosIns.put(`/profile/update/${user.value.id}`, payload);
        toast.success('Profile Updated', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000 
        });
        isLoading.value = false;

    } catch (error) {
        let errorMessage = 'An error occurred while updating the user.';

        if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
            errorMsg.value = errorMessage;
        }
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000 
        });


        isLoading.value = false;
    }
};

const fetchUserData = async () => {
    try {
        const response = await axiosIns.get('/user/show');
        const userData = response.data.userData;

        const [countryList, languageList] = await Promise.all([
            countryService.getCountries(),
            Promise.resolve(languages) 
        ]);

        const userCountry = countryList.find(c => c.location_code == userData.country)?.location_name || 'Unknown';
        const userLanguage = languageList.find(l => l.code === userData.language)?.name || 'Unknown';

        user.value = {
            ...userData,
            userCountry,
            userLanguage
        };

    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};


onBeforeMount(async () => {
    await fetchUserData();
    
    try {
        const data = await countryService.getCountries();
        regionList.value = data;
    } catch (error) {
        console.error("Error fetching countries:", error);
    }
});

</script>