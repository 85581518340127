
<template>
  <div class="view-serp-project-page-cont">
      <div class="view-serp-project-table-cont">
          <div class="view-serp-project-table-box">
              <div class="top-bar-project-info d-flex flex-wrap align-items-start gap-10 mb-30">
                  <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-file.svg" alt="Icon Add">
                  <span class="project-info-txt"><strong>Project : </strong>{{projectName}}</span>
              </div>
              <div class="box-top box-top-with-filter-new justify-content-between mb-30">
                <div class="table-top-left-info d-flex flex-wrap col-gap-10 row-gap-3 align-items-center">
                  <div class="table-top-left-info d-flex flex-wrap col-gap-10 row-gap-3 align-items-center">
                    
                    
                      <div class="form-group btn-group mb-0">
                          <button class="btn btn-light filter-btn add-keyword-btn" data-bs-toggle="modal" data-bs-target="#modal-add-keyword-view-serp-project">
                              <img class="btn-icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-add-btn.svg" alt="Icon Add">Add Keyword
                          </button>
                      </div>
                      <div class="form-group btn-group mb-0"  v-show="!isNormalView && userData?.type == 2" data-bs-toggle="modal" data-bs-target="#modal-add-competitor-view-serp-project">
                          <button class="btn btn-light filter-btn add-keyword-btn">
                              <img class="btn-icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-add-btn.svg" alt="Icon Add">Add Competitor
                          </button>
                      </div>
                    
                      <div class="form-group btn-group mb-0">
                        
                      </div>
                  </div>
                    <div class="form-group btn-group mb-0">
                        <button class="btn btn-light filter-btn delete-btn border-0" v-show="selectedIds.length > 0" @click="deleteSelected">
                            <img class="btn-icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin-btn.svg" alt="Icon Bin">Delete
                        </button>
                    </div>
               
            </div>
                  <div class="table-top-bar">
                      <div class="top-right justify-content-center">
                          <div class="competitors-view-toggle">
                              {{ isChecked ? "Competitors View" : "History View" }}
                              <div class="form-check form-switch" :class="{ active: isChecked }">
                                  <input class="form-check-input" type="checkbox" id="toggleSwitch" v-model="isChecked"  @change="!isChecked ? isNormalView = true : isNormalView = false"/>
                                  <label class="form-check-label" for="toggleSwitch">
                                      {{ isChecked ? "On" : "Off" }}
                                  </label>
                              </div>
                          </div>
                          <div class="form-group btn-group mb-0">
                              <button class="btn btn-light bg-main-primary-light-five border-0 filter-btn refresh-btn" title="Refresh" @click="refreshProject">
                                  <img class="icon-refresh" src="https://serpplus.pluspromotions.co.uk/images/icon-refresh.svg" alt="Refresh Icon" />
                              </button>
                          </div>
                          <div class="form-group btn-group mb-0">
                              <button class="btn btn-light filter-btn export-btn" @click="excelGenerate">
                                  <img class="flag-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-excel-export.svg" alt="Icon Excel Export">Export
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="bottom-bar-info d-flex flex-wrap justify-content-between gap-20 mb-30">
                  <span class="keyword-showing"><strong>Keywords: </strong>{{isNormalView ? summaryTextHistoryView : summaryTextCompetitorView}}</span>
                  <div class="next-prev-btn-cont d-flex flex-wrap gap-10" v-if="isNormalView">
                      <button class="btn btn-light admin-common-btn previous-btn" @click="showPreviousWeek" :disabled="currentWeekIndex <= 0">Previous</button>
                      <button class="btn btn-light admin-common-btn next-btn" @click="showNextWeek" :disabled="currentWeekIndex >= maxWeekIndex - 1">Next</button>
                  </div>
              </div>
            
              <div class="box-body" v-show="isNormalView">
                  <div class="data-table-cont">
                      <div id="example_wrapper" class="dataTables_wrapper">
                          <div class="row dt-row">
                              <div class="col-sm-12">
                                  <div class="table-responsive table-container table-view-serp table-list-projects"
                                      @scroll="handleScrollHistories">
                                      <table id="example" class="data-table table table-bordered table-striped">
                                          <thead class="sticky-top">
                                              <tr>
                                                  <th class="no-wrap text-start">Keywords</th>
                                                  <th class="no-wrap" v-for="(date, index) in filteredDates"
                                                      :key="index">
                                                      {{ formatDate(date) }}
                                                  </th>
                                              </tr>
                                          </thead>

                                          <tbody v-if="!isLoading">
                                              <template v-for="(item, index) in currentWeekData" :key="index">
                                                  <tr class="no-wrap">
                                                      <td>
                                                          <div
                                                              class="d-flex align-items-center column-gap-3 row-gap-3">
                                                              <input
                                                                  class="table-checkbox checkbox-new form-control mr-0"
                                                                  type="checkbox" v-model="selectedIds"
                                                                  :value="item.id" />
                                                              <p class="keyword-text text-start mb-0">
                                                                  {{ item.keyword }}
                                                                  <br />
                                                                  <span
                                                                      class="d-flex align-items-center column-gap-1">
                                                                      <strong>URL: </strong>
                                                                      <a class="kaywords-url-a" :href="item.url"
                                                                          target="_blank">{{ item.url }}</a>
                                                                  </span>
                                                              </p>
                                                          </div>
                                                      </td>
                                                      <td class="no-wrap text-center"
                                                          v-for="(date, i) in filteredDates" :key="i">
                                                          <div
                                                              class="d-flex column-gap-1 justify-content-center align-items-center">
                                                              <span
                                                                  v-if="item.details.find((d) => d.date === date)?.rank != null">
                                                                  <template
                                                                      v-if="item.details.find((d) => d.date === date)?.difference < 0">
                                                                      <span class="btn-keyword-up green-box">
                                                                          {{item.details.find((d) => d.date ===
                                                                          date)?.rank }}
                                                                          <span>
                                                                              ({{getFormattedDifference(item.details.find((d)=>d.date === date)?.difference) }})
                                                                          </span>
                                                                          <img class="icon-up-arrow-white"
                                                                              src="https://serpplus.pluspromotions.co.uk/images/icon-up-arrow-white-data-table-new.svg"
                                                                              alt="Up Arrow Icon" />
                                                                      </span>
                                                                  </template>
                                                                  <template
                                                                      v-else-if="item.details.find((d) => d.date === date)?.difference > 0">
                                                                      <span>
                                                                          {{item.details.find((d) => d.date ===
                                                                          date)?.rank }}
                                                                          <span class="text-danger">
                                                                              ({{ getFormattedDifference(item.details.find((d) => d.date === date)?.difference) }})
                                                                          </span>
                                                                          <img class="icon-down-arrow-red"
                                                                              src="https://serpplus.pluspromotions.co.uk/images/icon-down-arrow-orange-data-table-new.svg"
                                                                              alt="Down Arrow Icon" />
                                                                      </span>
                                                                  </template>
                                                                  <template v-else>
                                                                      {{item.details.find((d) => d.date ===
                                                                      date)?.rank }}
                                                                  </template>
                                                              </span>
                                                              <span v-else>-</span>
                                                          </div>
                                                      </td>
                                                  </tr>
                                              </template>
                                          </tbody>

                                          <tbody v-else>
                                              <tr v-for="i in 10" :key="i">
                                                  <td>
                                                      <div class="skeleton-loader skeleton-text w-100"></div>
                                                  </td>
                                                  <td v-for="date in filteredDates" :key="date">
                                                      <div class="skeleton-loader skeleton-box"></div>
                                                  </td>
                                              </tr>
                                              <tr>
                          <td colspan="100%" class="text-center py-3">
                             Loading more keywords...
                          </td>
                        </tr>
                                          </tbody>
                                      </table>
                                      <div style="display: flex; justify-content: center; align-items: center;margin-top: 5px">
    <span v-if="summaryTextHistoryViewFinal !== null">
        {{ summaryTextHistoryViewFinal }}
    </span>
</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="box-body" v-show="!isNormalView">

                  <div class="data-table-cont">
                      <div id="example_wrapper" class="dataTables_wrapper">
                          <div class="row dt-row">
                              <div class="col-sm-12">


                                  <div class="table-responsive table-container table-view-serp"
                                      @scroll="handleScrollCompetitors">

                                      <table id="example" class="data-table table table-border-none">
                                          <thead class="sticky-top">
                                              <tr>
                                                  <th class="no-wrap text-start width40">Keywords</th>
                                                  <th class="no-wrap text-start">Search Volume</th>
                                                  <th class="no-wrap text-start">KD</th>
                                                  <th class="no-wrap text-start">Ranking</th>
                                                  <th v-show="userData?.type == 2"
                                                      class="no-wrap text-start text-primary-new"
                                                      style="color: #68D2F5 !important;"
                                                      v-for="column in competitorsColumns" :key="column">{{ column }}
                                                  </th>
                                              </tr>
                                          </thead>

                                          <tbody v-if="!isLoading">
                                              <tr class="odd" v-for="(row, index) in allDataCompiters" :key="index">
                                                  <template v-for="(item, idx) in row.items" :key="idx">
                                                      <td class="no-wrap text-start">
                                                          <div
                                                              class="d-flex align-items-center column-gap-3 row-gap-3">
                                                              {{ item.keyword }}
                                                          </div>
                                                      </td>
                                                      <td class="no-wrap text-start">{{ item.search_volume }}</td>
                                                      <td class="no-wrap text-start">{{ item.kd }}</td>
                                                      <td class="no-wrap text-start">{{ item.rank }}</td>
                                                      <td class="no-wrap text-start" v-show="userData?.type == 2"
                                                          v-for="comp in item.competitors_items" :key="comp.id">
                                                          {{ comp.rank }}
                                                      </td>
                                                  </template>
                                              </tr>
                                          </tbody>

                                          <!-- Skeleton Loader -->
                                          <tbody v-else>
                                              <tr v-for="i in 10" :key="i">
                                                  <td class="no-wrap text-start">
                                                      <div class="skeleton-loader skeleton-text w-100"></div>
                                                  </td>
                                                  <td class="no-wrap text-start">
                                                      <div class="skeleton-loader skeleton-box"></div>
                                                  </td>
                                                  <td class="no-wrap text-start">
                                                      <div class="skeleton-loader skeleton-box"></div>
                                                  </td>
                                                  <td class="no-wrap text-start">
                                                      <div class="skeleton-loader skeleton-box"></div>
                                                  </td>
                                                  <td class="no-wrap text-start" v-show="userData?.type == 2"
                                                      v-for="comp in competitorsColumns" :key="comp">
                                                      <div class="skeleton-loader skeleton-box"></div>
                                                  </td>
                                              </tr>
                                              <tr>
                          <td colspan="100%" class="text-center py-3">
                             Loading more Competitors...
                          </td>
                        </tr>
                                          </tbody>
                                      </table>
                                      <div style="display: flex; justify-content: center; align-items: center;margin-top: 5px">
    <span v-if="summaryTextCompetitorFinal !== null">
        {{ summaryTextCompetitorFinal }}
    </span>
</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>


          </div>
      </div>
  </div>

  <copyRight></copyRight>

  <div id="modal-add-keyword-view-serp-project"
      class="common-modal-cont modal fade modal-add-keyword-view-serp-project" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-490 modal-dialog-centered">
          <div class="modal-content">
              <form action="">
                  <div class="modal-header">
                      <h5 class="modal-title">
                          <span class="mod-title">Add Keywords</span>
                      </h5>
                      <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                          <img src="https://serpplus.pluspromotions.co.uk/images/icon-popup-close.svg" alt="Close">
                      </a>
                  </div>
                  <div class="modal-body">
                      <div class="search-fld-cont search-tag-box">
                          <label>Keywords</label>
                          <div
                              class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">

                              <input class="form-control" type="text" v-model="newKeyword"
                                  placeholder="Enter your keywords for search" />

                              <a class="fld-add-icon" href="#" @click.prevent="addKeyword">
                                  <img class="img-fluid"
                                      src="https://serpplus.pluspromotions.co.uk/images/icon-search-add.svg"
                                      alt="Icon Reset" />
                              </a>
                          </div>
                          <span v-if="newKeywords.length <= 0 ? true : false" class="error-message">Keywords cannot be
                              empty.</span>
                          <div class="input-fld-cont search-tag-cont">
                              <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                  <li class="competitor-item d-flex align-items-center"
                                      v-for="(keyword, index) in newKeywords" :key="index">
                                      <span class="competitor-name">{{ keyword }}</span>
                                      <button class="remove-btn" aria-label="Remove competitor"
                                          @click.prevent="removeKeyword(index)">✕</button>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer flex-column flex-md-row justify-content-center justify-content-md-start">
                      <button class="admin-common-btn btn btn-primary align-self-start" type="submit"
                          data-bs-dismiss="modal" aria-label="Close"
                          :disabled="newKeywords.length <= 0 ? true : false"
                          @click.prevent="addKeywords">Submit</button>
                      <div class="mobile-close-modal">
                          <a class="btn-close" @click="closeModal" aria-label="Close">
                              <img src="https://serpplus.pluspromotions.co.uk/images/icon-popup-close.svg"
                                  alt="Close" />
                          </a>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>

  <div id="modal-add-competitor-view-serp-project"
      class="common-modal-cont modal fade modal-add-competitor-view-serp-project" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-490 modal-dialog-centered">
          <div class="modal-content">
              <form action="">
                  <div class="modal-header">
                      <h5 class="modal-title">
                          <span class="mod-title">Add Competitors</span>
                      </h5>
                      <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                          <img src="https://serpplus.pluspromotions.co.uk/images/icon-popup-close.svg" alt="Close">
                      </a>
                  </div>
                  <div class="modal-body">
                      <div class="search-fld-cont search-tag-box">
                          <label>Competitors</label>
                          <div
                              class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                              <input class="form-control" type="text" placeholder="Enter domains"
                                  v-model="newCompetitor" />
                              <a class="fld-add-icon" href="#">
                                  <img class="img-fluid"
                                      src="https://serpplus.pluspromotions.co.uk/images/icon-search-add.svg"
                                      alt="Icon Reset" @click.prevent="addCompetitor" />
                              </a>
                          </div>
                          <span v-if="competitorError" class="error-message">{{ competitorErrorMessage }}</span>

                          <div class="input-fld-cont search-tag-cont">
                              <ul class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                  <li v-for="(competitor, index) in newCompetitors" :key="index"
                                      class="competitor-item d-flex align-items-center">
                                      <span class="competitor-name">{{ competitor }}</span>
                                      <button type="button" @click="userData?.type != 1 && removeCompetitor(index)"
                                          class="remove-btn" aria-label="Remove competitor">✕</button>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer flex-column flex-md-row justify-content-center justify-content-md-start">
                      <button class="admin-common-btn btn btn-primary align-self-start" data-bs-dismiss="modal"
                          aria-label="Close" type="submit" :disabled="newCompetitors.length <= 0 ? true : false"
                          @click.prevent="addCompetitors">Submit</button>
                      <div class="mobile-close-modal">
                          <a class="btn-close" @click="closeModal" aria-label="Close">
                              <img src="https://serpplus.pluspromotions.co.uk/images/icon-popup-close.svg"
                                  alt="Close" />
                          </a>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>

</template>


<script setup>
import { ref, onMounted, watch, computed } from "vue";
import copyRight from "../../copyRight";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "bootstrap";
import EventBus from "@/EventBus";
import axiosIns from "@/axios";

const isNormalView = ref(true);
const isLoading = ref(false);
const userData = ref(null);
const compititerCount = ref(0);
const selectedIds = ref([]);

const getFormattedDifference = (difference) => {
  if (difference !== null) {
    return difference === 0
      ? "0"
      : (difference > 0 ? "-" : "+") + Math.abs(difference);
  } else {
    return "";
  }
};

const router = useRouter();
const histories = ref([]);

const allDataCompiters = ref([]);
const allDataHistories = ref([]);

const competitorsColumns = ref([]);
const projectId = ref("");

const currentPage = ref(1);
const lastPage = ref(1);

const currentPageHi = ref(1);
const lastPageHi = ref(1);

const allDates = ref([]);

const newKeyword = ref("");
const newKeywords = ref([]);

const addKeyword = () => {
  if (newKeyword.value.trim()) {
    newKeywords.value.push(newKeyword.value.trim());
    newKeyword.value = "";
  }
};

const removeKeyword = (index) => {
  newKeywords.value.splice(index, 1);
};

const newCompetitor = ref("");
const newCompetitors = ref([]);
const competitorError = ref(false);
const competitorErrorMessage = ref("");

const addCompetitor = () => {
  if (!validateCompetitor(newCompetitor.value)) {
    competitorError.value = true;
    competitorErrorMessage.value = "Please enter a valid domain.";
    return;
  }

  newCompetitors.value.push(newCompetitor.value.trim());
  newCompetitor.value = "";
  competitorError.value = false;
};

const removeCompetitor = async (index) => {
  if (index >= 0 && index < newCompetitors.value.length) {
    const removedCompetitor = newCompetitors.value.splice(index, 1)[0];

    const colIndex = competitorsColumns.value.indexOf(removedCompetitor);
    if (colIndex !== -1) {
      competitorsColumns.value.splice(colIndex, 1);
    }

    try {
      await axiosIns.post("/projects/remove-project-competitors", {
        project_id: router.currentRoute.value.params.id,
        competitorToRemove: [removedCompetitor],
      });
      toast.success(`Competitor "${removedCompetitor}" removed successfully`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      console.error("Error removing competitor:", error);

      toast.error(
        "An error occurred while removing the competitor. Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
          duration: 3000,
        }
      );
    }
  } else {
    console.error("Invalid index for removing competitor.");
    toast.error("Invalid competitor selection.", {
      position: toast.POSITION.TOP_RIGHT,
      duration: 3000,
    });
  }
};

const validateCompetitor = (domain) => {
  const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

  const trimmedDomain = domain.trim();

  if (!domainPattern.test(trimmedDomain)) {
    competitorError.value = true;
    competitorErrorMessage.value =
      "Please enter a valid domain. Example: 'google.com', 'amazon.com', or 'example.co.uk'.";
    return false;
  }

  if (
    newCompetitors.value.some(
      (existingDomain) =>
        existingDomain.toLowerCase() === trimmedDomain.toLowerCase()
    )
  ) {
    competitorError.value = true;
    competitorErrorMessage.value = "This competitor is already added.";
    return false;
  }

  competitorError.value = false;
  competitorErrorMessage.value = "";
  return true;
};

watch(selectedIds, (newSelectedIds, oldSelectedIds) => {
  newSelectedIds.filter((id) => !oldSelectedIds.includes(id));
  oldSelectedIds.filter((id) => !newSelectedIds.includes(id));
});

const projectName = ref("");
const currentWeekIndex = ref(0);
const weekSize = 7;

const handleScrollCompetitors = (event) => {
  const container = event.target;

  if (container.scrollHeight > container.clientHeight) {
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      loadMoreCompetitors();
    }
  }
};

const loadMoreCompetitors = () => {
  if (currentPage.value >= lastPage.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPage.value++;
    listCompetitors(router.currentRoute.value.params.id, currentPage.value);
  }, 1000);
};

const handleScrollHistories = (event) => {
  const container = event.target;

  if (container.scrollHeight > container.clientHeight) {
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      loadMoreHistories();
    }
  }
};

const loadMoreHistories = () => {
  if (currentPageHi.value >= lastPageHi.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPageHi.value++;
    listHistories(router.currentRoute.value.params.id, currentPageHi.value);
  }, 1000);
};

const summaryTextHistoryView = ref("");
const summaryTextHistoryViewFinal = ref(null);
const totalKeyowrds = ref(0);
const totalItemsHi = ref(0);
const listHistories = async (projectId, page) => {
  isLoading.value = true;
  try {
    const response = await axiosIns.get(
      `/projects/list-histories/${projectId}?page=${page}`
    );
    currentPageHi.value = Number(response.data.pagination.current_page);
    lastPageHi.value = Number(response.data.pagination.last_page);
    totalKeyowrds.value = response.data.pagination.total;
    projectName.value = response.data.projectName;
    totalItemsHi.value = response.data.pagination.total;
    if (!response.data.isItemNull) {
      isLoading.value = false;
      EventBus.$emit("loadCredits", true);
    }

    if (response.data.isItemNull) {
      isLoading.value = true;

      setTimeout(() => {
        window.location.reload();
      }, 50000);
    }

    if (response.data && response.data.historiesData?.[0]) {
      histories.value = response.data.historiesData[0];

      histories.value.forEach((history) => {
        const isDuplicate = allDataHistories.value.some(
          (existingHistory) => existingHistory.id === history.id
        );
        if (!isDuplicate) {
          allDataHistories.value.push(history);
        }
      });

      const totalAvailable = response.data.pagination.total;

      console.log(allDataHistories.value.length === totalAvailable);
      
      if (allDataHistories.value.length === totalAvailable) {
        summaryTextHistoryViewFinal.value = `All keywords are loaded`;
      }
      summaryTextHistoryView.value = `Showing ${allDataHistories.value.length} out of ${totalAvailable} keywords` ;

      const sortedDates = allDataHistories.value[0].dates.sort((a, b) => {
        const dateA = new Date(a.replace(" ", "T"));
        const dateB = new Date(b.replace(" ", "T"));
        return dateB - dateA;
      });

      allDates.value = sortedDates;

      isLoading.value = false;
    } else {
      console.error("Response data structure is not as expected");
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;
    alert("Error fetching project data");

    console.error("Error fetching customer data:", error);
  }
};

const maxWeekIndex = computed(() =>
  Math.ceil(allDates.value.length / weekSize)
);

const filteredDates = computed(() => {
  const start = currentWeekIndex.value * weekSize;
  const end = start + weekSize;
  return allDates.value.slice(start, end);
});

const currentWeekData = computed(() => {
  return allDataHistories.value.map((item) => {
    const filteredDetails = item.details.filter((detail) =>
      filteredDates.value.includes(detail.date)
    );
    return { ...item, details: filteredDetails };
  });
});

const showNextWeek = () => {
  if (currentWeekIndex.value < maxWeekIndex.value - 1) {
    currentWeekIndex.value++;
  }
};

const showPreviousWeek = () => {
  if (currentWeekIndex.value > 0) {
    currentWeekIndex.value--;
  }
};

const summaryTextCompetitorView = ref("");
const totalItemsCompetitor = ref(0);
const summaryTextCompetitorFinal = ref(null);
const listCompetitors = async (projectId, page) => {
  isLoading.value = true;
  try {
    if (!projectId) {
      console.error("projectId is not set");
      return;
    }

    const response = await axiosIns.get(
      `/projects/list-competitors/${projectId}?page=${page}`
    );
    currentPage.value = Number(response.data.pagination.current_page);
    lastPage.value = Number(response.data.pagination.last_page);
    projectName.value = response.data.projectName;

    if (response.data.isItemNull) {
      setTimeout(() => {
        let toastMessage = ` ${response.data.itemLeft} Keyword(s) left. Refreshing in 50 seconds...`;
        listCompetitors(router.currentRoute.value.params.id, currentPage.value);

        toast.success(toastMessage, {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000,
        });

        window.location.reload();
      }, 50000);

      isLoading.value = false;
    }

    totalItemsCompetitor.value = response.data.pagination.total;

    if (
      response.data?.competitorsData &&
      Array.isArray(response.data.competitorsData)
    ) {
      allDataCompiters.value.push(...response.data.competitorsData);

      if (response.data.competitorsData.length > 0) {
        const firstItem = response.data.competitorsData[0].items?.[0];

        compititerCount.value = firstItem?.competitor_count || 0;

        const existingCompetitors =
          firstItem?.competitors_items?.map((item) => item.name || "Unknown") ||
          [];

        competitorsColumns.value = existingCompetitors;

        newCompetitors.value = [
          ...new Set([...newCompetitors.value, ...existingCompetitors]),
        ];

        const totalCompetitors = response.data.pagination.total;

        if (allDataCompiters.value.length === totalCompetitors) {
          summaryTextCompetitorFinal.value = `All competitors are loaded`;
        } 
        summaryTextCompetitorView.value = `Showing ${allDataCompiters.value.length} out of ${totalCompetitors} competitors`;
      } else {
        compititerCount.value = 0;
        competitorsColumns.value = [];
        newCompetitors.value = [];
      }

      isLoading.value = false;
    } else {
      console.error(
        "Response data structure is invalid or competitorsData is missing."
      );
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;
    alert("Error fetching project data");
    console.error("Error fetching customer data:", error);
  }
};

const addCompetitors = async () => {
  if (compititerCount.value >= 10) {
    toast.error(
      "You have reached the maximum limit of 10 competitors for this project. Please remove an existing competitor before adding a new one.",
      {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      }
    );
    return false;
  }

  try {
    await axiosIns.put(`/projects/${projectId.value}`, {
      competitorsItems: newCompetitors.value,
    });

    toast.success(
      "The competitor has been added successfully to the project.",
      {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      }
    );
    window.location.reload();
    listCompetitors(router.currentRoute.value.params.id, currentPage.value);
  } catch (error) {
    toast.error(
      "Failed to add the competitor. Please try again later or contact support if the issue persists.",
      {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      }
    );

    console.error("Error while adding competitor:", error);
  }
};

const addKeywords = async () => {
  try {
    const response = await axiosIns.put(`/projects/${projectId.value}`, {
      keywordsItems: newKeywords.value,
    });

    toast.success(
      "Keywords have been successfully added to the project. Processing is underway and may take a few moments.",
      {
        position: toast.POSITION.TOP_RIGHT,
        duration: 10000,
      }
    );

    setTimeout(() => {
      window.location.reload();
    }, 10000);
  } catch (error) {
    toast.error(
      "Failed to add keywords. Please check your input or try again later.",
      {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      }
    );

    console.error("Error while adding keywords:", error);
  }
};

const excelGenerate = async () => {
  try {
    const response = await axiosIns.get(
      `/excel/generate-project/${router.currentRoute.value.params.id}`,
      {
        responseType: "blob",
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    const dateTime = new Date().toISOString().replace(/:/g, "-");
    link.setAttribute("download", `${projectName.value}_${dateTime}.xlsx`);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    toast.success("Excel File Generated", {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
    });
  } catch (error) {
    toast.error("Error While Generating Excel", {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
    });
  }
};

// const refreshProject = async () => {
//   isLoading.value = true;

//   try {
//     await axiosIns.post(`/projects/refresh-project/${projectId.value}`);

//     toast.success(
//   "The project data refresh is in progress. This may take anywhere from a minute to a maximum of five minutes, depending on traffic. Please be patient. If your data is not reloaded automatically, try refreshing again.",
//   {
//     position: toast.POSITION.TOP_RIGHT,
//     autoClose: 50000,
//   }
// );
// setTimeout(() => {
//   window.location.reload();
// }, 45000);

//   } catch (error) {
//     if (error.response) {
//       toast.error(`Error Refreshed Serp: ${error.response.data.message}`, {
//         position: toast.POSITION.TOP_RIGHT,
//         duration: 2000,
//       });
//     } else {
//       toast.error("Error Refreshed Serp", {
//         position: toast.POSITION.TOP_RIGHT,
//         duration: 2000,
//       });
//     }
//     console.error("Error While Project Refresh:", error);
//   }
// };

const refreshProject = async () => {
  try {
    await axiosIns.post(`/projects/refresh-project/${projectId.value}`);

    toast.success(
      "The project data is being refreshed. This may take a few minutes. If the data doesn’t update automatically, please try refreshing the page again.",
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 45000,
      }
    );

    setTimeout(() => window.location.reload(), 45000);
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Unexpected error occurred.";
    
    toast.error(`Error Refreshing SERP: ${errorMessage}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });

    console.error("Error While Refreshing Project:", error);
  }
};


const deleteSelected = async () => {
  if (confirm("Are you sure you want to delete the selected keywords?")) {
    try {
      const response = await axiosIns.post(
        `/projects/delete-project-keywords`,
        {
          keywords_ids: selectedIds.value,
        }
      );

      allDataHistories.value = allDataHistories.value.filter(
        (item) => !selectedIds.value.includes(item.id)
      );
      selectedIds.value = [];

      toast.success("Selected keywords deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      });
    } catch (error) {
      console.error("Error deleting selected keywords:", error);
    }
  }
};

function formatDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

onMounted(async () => {
  projectId.value = router.currentRoute.value.params.id;

  const data = localStorage.getItem("userData");
  userData.value = JSON.parse(data);

  if (projectId.value) {
    await listHistories(projectId.value, currentPageHi.value);
    await listCompetitors(projectId.value, currentPageHi.value);
  }
});
</script>

<style>
.data-table {
  width: 100%;
  border-collapse: collapse;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}

.details-container {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-top: 10px;
}

.details-header {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333333;
}

.table-bordered {
  width: 100%;
  border-collapse: collapse;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.table-bordered th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #495057;
}

.table-container {
  max-height: 680px !important;
  overflow-y: auto;
}

.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.details-container {
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.grid-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.grid-date,
.grid-rank {
  font-size: 1rem;
  color: #555;
}

.grid-date strong,
.grid-rank strong {
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.skeleton-loader {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-text {
  height: 20px;
  width: 100%;
}

.skeleton-box {
  height: 25px;
  width: 50px;
  margin: 0 auto;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
</style>
