<template>



  <div class="backlink-projects-page-cont"  v-if="userData?.type === 2">
    <div class="backlink-table-cont">
      <div class="backlink-table-box">
        
        <div class="box-top box-top-with-filter-new justify-content-between">
            <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                <router-link to="/backlink-overview" class="btn btn-light admin-common-btn me-4">
                    <img class="img-fluid"
                        src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg"
                        alt="Check Serp Icon" />
                        Analyze Backlinks
                </router-link>
            </div>
            <div class="table-top-bar">
                <div class="top-left">
                    <div class="form-group search-group">
                      <input class="table-search-input" placeholder="Search Here" v-model="searchQuery" @input="getBacklinkProjects" />
                    </div>
                </div>
            </div>
        </div>
        <div class="box-body">
          <div class="data-table-cont">
            <div class="table-responsive table-container table-normal-view table-backlink"  @scroll="handleScroll">
              <table
                id="example3"
                class="data-table table table-competitor-view dataTable no-footer"
              >
                <thead>
                  <tr>
                    <th class="no-wrap" style="width: 50% !important">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Domain</span>
                          <img
                            class="img-fluid th-icon"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle.svg"
                            alt="Question Icon"
                            title="Domain"
                          />
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Date Created</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Open Project</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Remove</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(project, index) in allData"
                    :key="index"
                  >
                    <td class="align-left no-wrap">
                      {{ project.target }}
                    </td>
                    <td class="align-left">
                      {{ new Date(project.created_at).toLocaleDateString() }}
                    </td>
                    <td class="align-center">
                      <p class="text-highlight">
                        <RouterLink
                          :to="`/backlink-view/${project.id}`"
                          class="text-primary"
                        >
                          Open
                          <img
                            class="img-fluid"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"
                            alt="Open Link Icon"
                            title="Open"
                          />
                        </RouterLink>
                      </p>
                    </td>
                    <td class="align-center">
                      <a
                        class="table-action-btn-new bg-signal-red-light btn-remove"
                        href="#"
                        @click.prevent="deleteBacklinkProject(project.id)"
                        title="Remove"
                      >
                        <img
                          class="img-fluid"
                          src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg"
                          alt="Remove Icon"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr v-if="isLoading">
                    <td colspan="4" class="text-center">
                      <p style="font-size: 1.2em">⏳ Loading...</p>
                    </td>
                  </tr>
                  <tr v-if="!isLoading && allData.length === 0">
                    <td colspan="4" class="text-center">
                      <p style="font-size: 1.2em">🧐 No Data Available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="common-inner-body backlinks-projects-page-cont" v-else>
    <div class="common-inner-cont">
      
      <div class="common-top-cont text-center mb-60">
          <div class="width900 d-inline-block">
          <h1 class="common-top-title fs-40 fw-normal text-yellow-two mb-15">Backlink Checker Module is Locked!</h1>
          <p class="common-top-p fs-20 lh-sm text-light-new mb-30">You don't have any active plan. Please upgrade to Comprehensive backlink analysis with detailed insights on domains, links, and rankings.</p>
          <a class="admin-common-btn admin-common-btn-new-big yellow-two" href="#"><img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> Upgrade Now & Unlock Fun Access!</a>
          </div>
      </div>

      <div class="common-text-img-box-cont">

          <div class="common-text-img-box">
              <div class="common-text-img-row row row-30">
                  <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                      <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Backlink Overview</h2>
                      <p class="box-p fs-16 mb-20">Get a complete picture of your website’s backlink profile to strengthen your SEO strategy:</p>
                      <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                          <li><strong class="text-dark-new fw-medium">Total Backlinks Count:</strong> Track the total number of backlinks pointing to your website.</li>
                          <li><strong class="text-dark-new fw-medium">Broken Backlinks Count:</strong> Identify and fix broken links that could harm your SEO performance.</li>
                          <li><strong class="text-dark-new fw-medium">External & Internal Links Count:</strong> Analyze both inbound and outbound link structures for better optimization.</li>
                          <li><strong class="text-dark-new fw-medium">Referring Domains Count:</strong> See how many unique domains are linking to your website.</li>
                          <li><strong class="text-dark-new fw-medium">Crawled Pages Analysis:</strong> Understand which pages have the most backlinks and how search engines are crawling them.</li>
                      </ul>
                  </div>
                  <div class="col-12 col-lg-5 img-col col-30">
                      <div class="img-box">
                          <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image10.jpg" alt="Box Image" />
                      </div>
                  </div>
              </div>
          </div>

          <div class="common-text-img-box">
              <div class="common-text-img-row row row-30">
                  <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                      <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Referring Domains Breakdown</h2>
                      <p class="box-p fs-16 mb-20">Gain deeper insights into your referring domains and their impact:</p>
                      <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                          <li><strong class="text-dark-new fw-medium">Total Referring Domains:</strong> See how many unique domains are linking to your site.</li>
                          <li><strong class="text-dark-new fw-medium">Nofollow Referring Domains:</strong> Identify links that do not pass SEO value and analyze their impact.</li>
                          <li><strong class="text-dark-new fw-medium">Main Referring Domains:</strong> Discover the most influential domains linking to your website.</li>
                          <li><strong class="text-dark-new fw-medium">Nofollow Main Domains:</strong> Track which top referring domains use nofollow attributes on their links.</li>
                      </ul>
                  </div>
                  <div class="col-12 col-lg-5 img-col col-30">
                      <div class="img-box">
                          <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image11.jpg" alt="Box Image" />
                      </div>
                  </div>
              </div>
          </div>

          <div class="common-text-img-box">
              <div class="common-text-img-row row row-30">
                  <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                      <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Detailed Chart Analysis</h2>
                      <p class="box-p fs-16 mb-20">Visualize backlink data for better decision-making:</p>
                      <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                        <li><strong class="text-dark-new fw-medium">Referring Links by Type:</strong> Breakdown of link types, including redirects, anchor links, image links, canonical links, and more.</li>
                        <li><strong class="text-dark-new fw-medium">Links Distribution by TLD:</strong> See how backlinks are spread across .com, .org, .net, and other domain extensions.</li>
                        <li><strong class="text-dark-new fw-medium">Backlinks Overview & Links by Country:</strong> Identify backlink sources and analyze country-wise distribution.</li>
                        <li><strong class="text-dark-new fw-medium">New vs. Lost Domains Analysis:</strong> Track how your backlink profile is evolving over time.</li>
                      </ul>
                  </div>
                  <div class="col-12 col-lg-5 img-col col-30">
                      <div class="img-box">
                          <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image12.jpg" alt="Box Image" />
                      </div>
                  </div>
              </div>
          </div>

          <div class="common-text-img-box">
              <div class="common-text-img-row row row-30">
                  <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                      <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Main Data Table</h2>
                      <p class="box-p fs-16 mb-20">Access a structured, detailed table with key backlink metrics:</p>
                      <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                        <li><strong class="text-dark-new fw-medium">Page Titles with Associated Links:</strong> View the exact pages linking to your content.</li>
                        <li><strong class="text-dark-new fw-medium">Domain Rank & Page Rank:</strong> Evaluate the authority of linking domains and pages.</li>
                        <li><strong class="text-dark-new fw-medium">External & Internal Links Count:</strong> See how links are distributed across your website.</li>
                        <li><strong class="text-dark-new fw-medium">Anchor Text Analysis:</strong> Understand the context of links with detailed anchor text insights.</li>
                        <li><strong class="text-dark-new fw-medium">First Seen & Last Seen Timestamps:</strong> Track when a backlink was first detected and its latest status.</li>
                        <li><strong class="text-dark-new fw-medium">Active Links Tracking:</strong> Monitor which backlinks are still active and which have been removed.</li>
                      </ul>
                  </div>
                  <div class="col-12 col-lg-5 img-col col-30">
                      <div class="img-box">
                          <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image13.jpg" alt="Box Image" />
                      </div>
                  </div>
              </div>
          </div>

          <div class="common-text-img-box">
              <div class="common-text-img-row row row-30">
                  <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
                      <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Export Reports</h2>
                      <p class="box-p fs-16 mb-20">Easily download and analyze backlink insights:</p>
                      <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
                        <li><strong class="text-dark-new fw-medium">Comprehensive Excel Exports:</strong> Download detailed reports on backlinks, referring domains, and traffic analysis for further study and offline access.</li>
                        <li><strong class="text-dark-new fw-medium">Actionable Insights:</strong> Use exported data to refine your backlink strategy, detect lost links, and improve your link-building efforts.</li>
                      </ul>
                  </div>
                  <div class="col-12 col-lg-5 img-col col-30">
                      <div class="img-box">
                          <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image14.jpg" alt="Box Image" />
                      </div>
                  </div>
              </div>
          </div>

          <div class="upgrade-message-container upgrade-big w-100">
              <h2 class="upgrade-title">Purchase A Plan To Unlock This Feature</h2>
              <p class="upgrade-text text-light-new">You don't have any active plan. Please purchase a plan to take your SEO game to the next level!</p>
              <div class="upgrade-btn-cont">
                  <router-link to='/pricing-page' class="upgrade-link admin-common-btn admin-common-btn-new-big yellow-two"><img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> View Planes</router-link>
              </div>
          </div>

      </div>

    </div>
  </div>
  <CopyRight></copyRight>

</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import CopyRight from '../copyRight.vue';
import axiosIns from "@/axios";
import { RouterLink } from "vue-router";
import { toast } from "vue3-toastify";

const searchQuery = ref("");
const currentPage = ref(1);
const lastPage = ref(1);
const isLoading = ref(false);

const allData = ref([]);

const handleScroll = (event) => {
  const container = event.target;

  if (container.scrollHeight > container.clientHeight) {
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      loadMore();
    }
  }
};

const loadMore = () => {
  if (currentPage.value >= lastPage.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPage.value++;
    getBacklinkProjects();
  }, 1000);
};

const getBacklinkProjects = async () => {
  isLoading.value = true;

  try {
    const response = await axiosIns.get('/backlink/get-backlink-projects', {
      params: {
        page: currentPage.value, 
        per_page: 10, 
      },
    });

  
    if (currentPage.value === 1) {
      allData.value = response.data.data;
    } else {
      allData.value.push(...response.data.data); 
    }

    lastPage.value = response.data.pagination.last_page;
    currentPage.value = response.data.pagination.current_page;

    isLoading.value = false;
  } catch (error) {
    console.error('Error fetching backlink projects:', error);
    isLoading.value = false;
  }
};


const deleteBacklinkProject = async (id) => {
  const confirmDelete = confirm(
    "Are you sure you want to delete this project?"
  );

  if (!confirmDelete) {
    return;
  }

  try {
    await axiosIns.delete(`/backlink/delete-backlink-project/${Number(id)}`);
    toast.success("Project deleted successfully");
  } catch (error) {
    console.error("Error deleting project:", error);
    toast.error("Failed to delete project");
  }
};
const userData = ref(null)

onMounted(async () => {
  await getBacklinkProjects();
  const data = localStorage.getItem('userData');
  userData.value = JSON.parse(data);
});
</script>


<style scoped>


.table-container {
  max-height: 1500px;
  overflow-y: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}</style>