<template>
  <div class="log-cont">
    <div class="container-fluid log-container">
      <div class="row log-row">
        <div class="col-12 col-md-7 col-left">
          <div class="left-cont">
            <img class="log-banner-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/log-page-bg.jpg" alt="Log Left Image">
          </div>
        </div>
        <div class="col-12 col-md-5 col-right">
            <div class="right-cont">
                <div class="log-form-box">
                  <a class="router-link-active router-link-exact-active log-logo" href="/login">
                      <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/logo-login-new.svg" alt="SerpPlus">
                  </a>
                  <form @submit.prevent="submitForm">
                    <h1 class="log-title">Forgot Your Password?</h1>
                    <p class="log-sub-title">No worries! Follow the steps below to reset your password and continue your SEO game.</p>
                    <div class="form-group">
                      <label>Enter your email<span class="mand-star">*</span></label>
                      <input v-model="form.email" class="form-control" type="email" placeholder="mail@example.com" :class="{ 'is-invalid': emailValidationFailed }" />
                      <div v-if="emailValidationFailed" class="invalid-feedback">Please enter a valid email address.</div>
                    </div>
                    <Button class="form-common-btn btn btn-primary w-100 mt-10 mb-30" :loading="isLoading" type="submit">Send OTP</Button>
                    <div class="form-text forgot-pass-cra text-center mt-0">
                      Need New Account? <router-link to="/register" class="form-a">Register</router-link>
                    </div>
                  </form>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import axiosIns from '@/axios';
import { ref } from 'vue';
import { toast } from 'vue3-toastify';
import { useRouter } from 'vue-router';

const isLoading = ref(false)
const router = useRouter();
const form = ref({
  email: ''
});

const emailValidationFailed = ref(false);

const submitForm = async () => {
  emailValidationFailed.value = false;

  if (!isValidEmail(form.value.email)) {
    emailValidationFailed.value = true;
    toast.error('Invalid email format.');
    return;
  }

  try {
    isLoading.value = true
    await axiosIns.post('/reset-password', form.value);
    toast.success('Mail sent', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 3000 // 3000 milliseconds timeout
    });
    isLoading.value = false
    setTimeout(() => {
      router.push('/login');
    }, 3000);
  } catch (error) {
    isLoading.value = false
    console.error('Reset password failed:', error);
    toast.error('Failed to send reset password email.');
  }
}


const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


</script>
  
<style scoped>
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  /* color: red;
  font-size: 14px; */
}
</style>
  