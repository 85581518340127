<template>
    <div class="common-inner-body drc-page-cont">
        <div class="common-inner-cont">
            <div class="common-box">
                <h2 class="module-title fs-20 fw-semibold mb-20">Module Credit Allocation</h2>

                <div class="data-table-cont">
                    <div class="table-responsive table-container table-view-serp table-credit-settings">
                        <table id="example" class="data-table table table-border-none mb-0">
                            <thead>
                                <tr class="bg-gray-200 text-gray-700 text-center">
                                    <th class="no-wrap">Title</th>
                                    <th class="no-wrap">Total Cost</th>
                                    <th class="no-wrap">Credit to Offer</th>
                                    <th class="no-wrap">Cost per Credit</th>
                                    <th class="no-wrap">Task Cost</th>
                                    <th class="no-wrap">Keyword Cost</th>
                                    <th class="no-wrap">Transactions</th>
                                    <th class="no-wrap">Cost for Transaction</th>
                                    <th class="no-wrap">Credit Used Per Transaction</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in rows" :key="index">
                                    <td class="text-center">{{ row.title }}</td>
                                    <td class="text-center">{{ row.totalCost.toFixed(2) }}</td>
                                    <td class="text-center">
                                        <input v-model.number="row.creditOffer" type="number" class="form-control text-center" @input="calculateValues(index)" />
                                    </td>
                                    <td class="text-center">{{ row.costPerCredit.toFixed(6) }}</td>
                                    <td class="text-center">
                                        <input v-model.number="row.taskCost" type="number" step="0.01" class="form-control text-center" @input="calculateValues(index)" />
                                    </td>
                                    <td class="text-center">
                                        <input v-model.number="row.keywordCost" type="number" step="0.0001" class="form-control text-center" @input="calculateValues(index)" />
                                    </td>
                                    <td class="text-center">
                                        <input v-model.number="row.transactions" type="number" class="form-control text-center" @input="calculateValues(index)" />
                                    </td>
                                    <td class="text-center">{{ row.costForTransaction.toFixed(4) }}</td>
                                    <td class="text-center">{{ row.creditUsedPerTransaction.toFixed(2) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="text-center mt-4">
                    <button @click="onCreditChange" class="btn btn-primary" style="background-color: #45C9F4">Update Credits</button>
                </div>
            </div>
        </div>
    </div>

    <copyRight></copyRight>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import copyRight from '../copyRight';
import axiosIns from '@/axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const rows = reactive([
    { title: 'SERPS', totalCost: 0.11, creditOffer: 300, costPerCredit: 0.000366667, taskCost: 0, keywordCost: 0.0012, transactions: 1, costForTransaction: 0.0012, creditUsedPerTransaction: 3.27 },
    { title: 'DOMAIN', totalCost: 0.11, creditOffer: 300, costPerCredit: 0.000366667, taskCost: 0.02, keywordCost: 0.00003, transactions: 10, costForTransaction: 0.0203, creditUsedPerTransaction: 55.36 },
    { title: 'BACKLINKS', totalCost: 0.11, creditOffer: 300, costPerCredit: 0.000366667, taskCost: 0.01, keywordCost: 0.0001, transactions: 1000, costForTransaction: 0.11, creditUsedPerTransaction: 300 }
]);

const calculateValues = (index) => {
    let row = rows[index];

    row.creditOffer = Number(row.creditOffer) || 0;
    row.taskCost = Number(row.taskCost) || 0;
    row.keywordCost = Number(row.keywordCost) || 0;
    row.transactions = Number(row.transactions) || 1;

    row.costPerCredit = row.totalCost / row.creditOffer;
    row.costForTransaction = row.keywordCost + row.taskCost;
    row.creditUsedPerTransaction = row.costForTransaction / row.costPerCredit;
};

const fetchCredits = async () => {
    try {
        const response = await axiosIns.get('/credits');
        if (response.data.success && response.data.data) {
            const fetchedData = JSON.parse(response.data.data);
            fetchedData.forEach((item, index) => {
                if (rows[index]) {
                    Object.assign(rows[index], item); 
                }
            });
            toast.info('Data refreshed!', { position: 'top-right', autoClose: 2500 });
        }
    } catch (error) {
        toast.error('Error fetching credits.', { position: 'top-right', autoClose: 4000 });
        console.error('Error fetching credits:', error);
    }
};

const onCreditChange = async () => {
    try {
        await axiosIns.post('/credits/manage', { data: rows });

        toast.success('Credits updated successfully!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });

        setTimeout(() => fetchCredits(), 1500); 

    } catch (error) {
        toast.error('Failed to update credits. Try again.', {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    }
};

onMounted(fetchCredits);
</script>


