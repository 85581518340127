<template>

    <loader :active="loading" message="Please wait..." />   

    <div class="common-inner-body customer-list-page-cont">
        <div class="common-inner-cont">
            <div class="common-box">
                <div class="box-top box-top-with-filter-new justify-content-between">
                    <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                        <a class="admin-common-btn cursor-pointer d-inline-flex gap-10 align-items-center" data-bs-toggle="modal" @click="addQuestion" data-bs-target="#modal-add-question"><img class="icon-img" src="https://serpplus.pluspromotions.co.uk/images/icon-plus.svg" alt="Icon Plus"> Add New Question</a>
                    </div>
                    <div class="table-top-bar">
                        <div class="top-left">
                            <div class="form-group search-group">
                            <input class="table-search-input" placeholder="Search Here" v-model="searchTerm"  @input="fetchProjects" />
                            </div>
                        </div>
                        <!-- <div class="top-right">
                            <div class="form-group btn-group">
                                <button class="btn btn-light filter-btn search-btn">Search</button>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="data-table-cont">
                    <div class="table-responsive table-container table-view-serp table-manage-question">
                        <table id="example" class="data-table table table-border-none mb-0">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap width15 text-start">Category</th>
                                    <th class="no-wrap width25 text-start min-width230">Question</th>
                                    <th class="no-wrap width40 text-start min-width230">Question Answer</th>
                                    <th class="no-wrap width10">Edit Question</th>
                                    <th class="no-wrap width10">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in questions" :key="index">
                                    <td class="valign-top no-wrap text-start">{{ item.categoryName }}</td>
                                    <td class="valign-top text-start">{{ item.question }}</td>
                                    <td class="valign-top text-start">{{ item.answer }}</td>
                                    <td class="valign-top no-wrap text-center">
                                        <div class="action-icon-cont">
                                            <a class="table-action-btn-new bg-yellow-light-two icon-edit" @click="editQuestion(item)"
                                                data-bs-toggle="modal" data-bs-target="#modal-edit-question">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-edit-faq-cat.svg" alt="Edit">
                                            </a>
                                        </div>
                                    </td>
                                    <td class="valign-top no-wrap text-center">
                                        <div class="action-icon-cont">
                                            <a class="table-action-btn-new bg-signal-red-light btn-remove" @click="deleteQuestion(item)">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon">
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL - ADD QUESTION - START -->
    <div id="modal-add-question" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-690 modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title flex-column">
                        <span class="mod-title">Add Question</span>
                        <p class="modal-sub-title">Add Your New Question</p>
                    </h5>
                    <a class="btn-close"  data-bs-dismiss="modal" aria-label="Close">
                        <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Icon Close">
                    </a>
                </div>
                <div class="modal-body">
                    <div class="modal-form-cont check-serp-form-cont">
                        <div class="form-group">
                            <label>Category</label>
                            <select class="form-control" required v-model="form.category_id">
                                <option value="">Select Category</option>
                                <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Question</label>
                            <input class="form-control" type="text" v-model="form.question" placeholder="Enter Question" required>
                        </div>
                        <div class="form-group">
                            <label>Answer</label>
                            <textarea class="form-control height-211" placeholder="Enter Answer" v-model="form.answer" required></textarea>
                        </div>
                        <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
                    </div>
                </div>
                <div class="modal-footer col-gap-20 row-gap-10">
                    <button class="admin-common-btn btn-light-red btn-cancel" type="button" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button class="admin-common-btn btn btn-primary btn-save" @click="saveQuestion" data-bs-dismiss="modal">Save</button>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD QUESTION - END -->

    <!-- MODAL - EDIT QUESTION - START -->
    <div id="modal-edit-question" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-690 modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title flex-column">
                        <span class="mod-title">Edit Question</span>
                        <p class="modal-sub-title">Update Your Question</p>
                    </h5>
                    <a class="btn-close"  data-bs-dismiss="modal" aria-label="Close">
                        <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Icon Close">
                    </a>
                </div>
                <div class="modal-body">
                    <div class="modal-form-cont check-serp-form-cont">
                        <div class="form-group">
                            <label>Category</label>
                            <select class="form-control" required v-model="form.category_id">
                                <option value="">Select Category</option>
                                <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Question</label>
                            <input class="form-control" type="text" v-model="form.question" placeholder="Enter Question" required>
                        </div>
                        <div class="form-group">
                            <label>Answer</label>
                            <textarea class="form-control height-211" placeholder="Enter Answer" v-model="form.answer" required></textarea>
                        </div>
                        <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
                    </div>
                </div>
                <div class="modal-footer col-gap-20 row-gap-10">
                    <button class="admin-common-btn btn-light-red btn-cancel" type="button" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button class="admin-common-btn btn btn-primary btn-save-changes" @click="updateQuestion" data-bs-dismiss="modal">Save Changes</button>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL - EDIT QUESTION - END -->

    <CopyRight></copyRight>

</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import CopyRight from '../copyRight.vue';
import axiosIns from '../../axios';
import { useAbility } from '@casl/vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
const { can } = useAbility();
import loader from '../Loader.vue';

const questions = ref([]);
const categories = ref([]);
const questionId = ref('');

const form = ref({});
const loading = ref(false)

const addQuestion = () => {
    form.value = {};
};

const editQuestion = (question) => {
    form.value = question;
    questionId.value = question.id;

};

const saveQuestion = async () => {
    try {
        await axiosIns.post('questions', form.value);
        toast.success('Question Created', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    } catch (error) {
        console.error('Error:', error.message);
    }
    fetchQuestions();
};

const updateQuestion = async () => {
    try {
        await axiosIns.put(`questions/${questionId.value}`, form.value);
        toast.success('Question Updated', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    } catch (error) {
        console.error('Error:', error.message);
    }
    fetchQuestions();
};

const fetchCategoryNames = async () => {
    try {
        const data = await axiosIns.get('category/names');
        categories.value = data.data.categories;
    } catch (error) {
        console.error('Error:', error.message);
    }
};

const fetchQuestions = async () => {
    loading.value = true
    try {
        const data = await axiosIns.get('questions');
        loading.value = false
        questions.value = data.data.questions;
    } catch (error) {
        console.error('Error:', error.message);
    }
};


const deleteQuestion = async (question) => {
    try {
        const confirmed = confirm("Are you sure you want to delete this question?");

        if (confirmed) {
            const questionId = question.id;

            await axiosIns.delete(`/questions/${questionId}`);

            toast.success('Question Deleted', {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
            fetchQuestions()

            }
    } catch (error) {
        console.error('Error deleting question:', error.message);
    }
};


onMounted(() => {
    fetchCategoryNames()
    fetchQuestions()
});
</script>