<template>
  <div class="common-inner-body manage-account-page-cont">
    <div class="common-inner-cont">
      <div class="account-main-cont row row-gap-30">
        <div class="account-left-cont col-12 col-lg-6 col-xl-5 col-xxl-4">
          <div class="profile-info-cont">
            <div class="profile-info-box d-flex flex-column align-items-center common-border-new border-radius15 position-relative p-30 pt-60 mb-30">
              <span class="profile-picture d-flex justify-content-center align-items-center rounded-circle p-10 mb-20">
                {{ form.name ? form.name.charAt(0).toUpperCase() : 'N' }}
              </span>
              <h2 class="customer-name fs-30 fw-semibold text-dark-new mb-10">{{ form.name }}</h2>
              <span class="customer-type bg-body-color p-10 border-radius5 text-light-new mb-10">Customer</span>
              <h3 className="customer-type-title fs-20 fw-semibold text-dark-new mb-20">
                <span class="text-dark-new d-block text-center">
                  {{selectedPlanName === "No active plan"
                  ? "No active plan"
                  : `Serp Plus ${selectedPlanName} member`}}
                </span>                  
                <!-- <span class="text-yellow-two d-block text-center">Subscription expired</span> -->
              </h3>
              <div class="profile-info-boxes-cont row-gap-20 mb-20">
                <div class="info-box d-flex align-items-center col-gap-10">
                  <div class="info-box-left bg-yellow-light border-radius10 p-10">
                    <img class="icon-profile-info img-fluid d-block ratio-1x1 w42"
                      src="https://serpplus.pluspromotions.co.uk/images/icon-profile-credit-yellow.svg"
                      alt="Icon Profile Credit">
                  </div>
                  <div class="info-box-right">
                    <p class="info-number text-yellow-two fs-30 lh-1 mb-0">{{ form.credits }}</p>
                    <p class="info-text text-light-new fs-20 lh-1 mb-0">Credits Left</p>
                  </div>
                </div>               
              </div>
              <div class="profile-details-box-cont w-100">
                <h5 class="title-profile-details text-primary-new fs-20 mb-20">Details</h5>
                <hr class="border-one mt-0 mb-15 opacity-100">
                <p class="p-profile-detail mb-10"><strong>User Id:</strong> {{ form.id }}</p>
                <p class="p-profile-detail mb-10">
                  <strong>Phone Number:</strong> 
                  <a class="text-light-new text-decoration-none" :href="form.contact ? `tel:${form.contact}` : '#'">
                    {{ form.contact || "Not Provided" }}
                  </a>
                </p>
                <p class="p-profile-detail mb-10">
                  <strong>Email:</strong> 
                  <a class="text-light-new text-decoration-none" :href="form.email ? `mailto:${form.email}` : '#'">
                    {{ form.email || "Not Provided" }}
                  </a>
                </p>
                <p class="p-profile-detail mb-10">
                  <strong>Country:</strong> <span class="text-light-new">{{ form.country || "Not Provided" }}</span>
                </p>
                <p class="p-profile-detail mb-10">
                  <strong>Languages:</strong> <span class="text-light-new">{{ form.language || "Not Provided" }}</span>
                </p>
              </div>
              <div class="bottom-btn-cont d-flex flex-wrap justify-content-start col-gap-20 row-gap-20 mt-10 w-100">
                <!-- Edit Profile Button -->
                <a class="admin-common-btn" data-bs-toggle="modal" data-bs-target="#modal-add-question">Edit Profile</a>
                <!-- Single Suspend Button for Desktop and Mobile -->
                <button class="admin-common-btn btn-light-red" @click="updateCustomer" 
                        :class="form.suspend === 1 ? 'btn-suspended btn-red' : 'btn-active'">
                  <img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-lock.svg" alt="Icon Lock">
                  <span v-if="form.suspend === 1">Suspended </span>
                  <span v-else>Click to Suspend </span>
                </button>
              </div>             
            </div>
            <div class="profile-info-box common-border-new border-radius15 position-relative p-30"
              v-if="isUserPlanValid">
              <div class="top-plan-price d-flex justify-content-between align-items-center col-gap-10">
                <span
                  class="customer-type bg-main-primary-light-five text-primary-new lh-1 p-10 border-radius5 mb-30">{{
                    selectedPlan.name }}</span>
                <h2 class="plan-price d-flex justify-content-center align-items-center col-gap-5 mb-30">
                  <span class="price d-inline-block">
                    {{ selectedInterval === 'month' ? selectedPlan.monthly_price : selectedPlan.yearly_price }}
                  </span>
                  <span class="currency-period">
                    <span class="currency-period">
                      <b class="currency d-block text-start">euro</b>
                      <b class="period d-block text-start">
                        {{ selectedInterval === 'month' ? '/mo.' : '/yr.' }}
                      </b>
                    </span>
                  </span>
                </h2>
              </div>
              <ul class="list-inline">
                <li class="list-inline-item mr-20 mb-20"><img class="icon-tick img-fluid d-inline ratio-1x1 w20 mr-10"
                    src="https://serpplus.pluspromotions.co.uk/images/icon-check.svg"
                    alt="Icon Check"> SERP Checker</li>
                <li class="list-inline-item mr-20 mb-20"><img class="icon-tick img-fluid d-inline ratio-1x1 w20 mr-10"
                    src="https://serpplus.pluspromotions.co.uk/images/icon-check.svg"
                    alt="Icon Check"> SERP Projects</li>
                <li class="list-inline-item mr-20 mb-20"><img class="icon-tick img-fluid d-inline ratio-1x1 w20 mr-10"
                    src="https://serpplus.pluspromotions.co.uk/images/icon-check.svg"
                    alt="Icon Check"> Domain Checker</li>
                <li class="list-inline-item mr-20 mb-20"><img class="icon-tick img-fluid d-inline ratio-1x1 w20 mr-10"
                    src="https://serpplus.pluspromotions.co.uk/images/icon-check.svg"
                    alt="Icon Check"> Backlink Module</li>
              </ul>
              <div v-if="remainingDays > 0" class="day-remaining-cont">
                <p
                  class="day-remaining-top d-flex justify-content-between align-items-center col-gap-20 fw-medium mb-15">
                  <span>Days</span>
                  <span>{{ remainingDays }} of {{ totalDays }} Days</span>
                </p>
                <div class="progress common-progress mb-20" role="progressbar" aria-label="Basic example"
                  :aria-valuenow="progressWidth" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-bar" :style="{
                    width: progressWidth + '%'
                  }"></div>
                </div>
                <p class="text-start text-light-new">{{ remainingDays }} days remaining</p>
              </div>
            </div>
          </div>
        </div>
        <div class="account-right-cont d-flex flex-column col-12 col-lg-6 col-xl-7 col-xxl-8">
          <div class="box-top box-top-with-filter-new justify-content-between">
            <ul class="nav nav-tabs border-0 col-gap-10 row-gap-10" id="myTab" role="tablist">
              <li class="nav-item w-xs-100" role="presentation" v-for="(tab, index) in tabs" :key="index">
                <button
                  :class="['nav-link', 'btn', 'btn-light', 'admin-common-btn', 'bg-transparent', 'text-dark-new', 'border-0', 'w-100', { active: activeTab === tab }]"
                  @click="activeTab = tab">
                  <img class="img-fluid mr-10" :src="icons[index]" :alt="`${tab} Icon`">
                  {{ tab }}
                </button>
              </li>
            </ul>
          </div>
          <div class="profile-info-box common-border-new border-radius15 position-relative p-30 pt-20"
            v-if="activeTab == 'Purchase History'">
            <div class="data-table-cont">
              <div id="example_wrapper" class="dataTables_wrapper">
                <div class="row dt-row">
                  <div class="col-sm-12">
                    <div class="table-responsive table-container table-view-serp h-max-unset min-height350">
                      <table id="example" class="data-table table table-border-none">
                        <thead class="sticky-top">
                          <tr>
                            <th class="no-wrap text-start">Plan Name</th>
                            <th class="no-wrap text-start">Credits</th>
                            <th class="no-wrap text-start">Price</th>
                            <th class="no-wrap text-start">Purchase Date</th>
                            <th class="no-wrap text-start">Validity</th>
                            <th class="no-wrap text-start">Status</th>
                            <th class="no-wrap text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="purchaseHistory.length === 0">
                            <td colspan="8" class="text-center empty-message">
                              💃 <strong>No purchases yet!</strong>
                              <br />
                            </td>
                          </tr>
                          <tr v-for="(purchase, index) in purchaseHistory" :key="index">
                            <td class="no-wrap text-start"> {{ purchase.name || 'N/A' }}{{ purchase.name !== 'Top Up' &&
                              purchase.interval ? ' | ' + purchase.interval : '' }}</td>
                            <td class="no-wrap text-start"><img class="img-fluid ratio-1x1 h20"
                                src="https://serpplus.pluspromotions.co.uk/images/icon-credit-coin.svg"
                                alt="Icon Coin"> {{ purchase.credits || 'N/A' }}</td>
                            <td class="no-wrap text-start"><img class="img-fluid ratio-1x1 h20"
                                src="https://serpplus.pluspromotions.co.uk/images/icon-euro.svg"
                                alt="Icon Euro"> {{ purchase.price ? purchase.price : 'N/A' }}</td>
                              
                            <td class="no-wrap text-start">{{ purchase.purchase_date || 'N/A' }}</td>
                          
                            <td class="no-wrap text-start">{{ purchase.expire_date || 'N/A' }}</td>
                            <td class="no-wrap text-start">
    {{ purchase.is_expired_plan ? '❌ Expired' : '✅ Active' }}
</td>
                            <td class="no-wrap text-center">
                              <div class="dropdown">
                                <a class="table-three-dot-btn d-flex justify-content-center align-items-center rounded-circle p-5 ratio-1x1 h40 w40 mx-auto"
                                  href="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <img class="img-fluid ratio-1x1 h20"
                                    src="https://serpplus.pluspromotions.co.uk/images/icon-three-dot-action.svg"
                                    alt="Icon 3 Dot">
                                </a>
                                <ul class="dropdown-menu border-0 box-shadow-five-double border-radius15 p-20">
                                  <li><a class="dropdown-item bg-hover-none text-light-new mb-20 p-0"
                                      href="#">Details</a></li>
                                  <li><a class="dropdown-item bg-hover-none text-light-new mb-20 p-0"
                                      href="#">Archive</a></li>
                                  <hr class="border-one mt-0 mb-20 opacity-100">
                                  <li><a class="dropdown-item bg-hover-none text-red p-0" href="#">Delete</a></li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-info-box common-border-new border-radius15 position-relative h-100 p-30 pt-20"
            v-if="activeTab === 'Security'">
            <div class="info-box-cont">
              <h2 class="info-box-title">Change Password</h2>
              <h4 class="info-box-subtitle">Manage your password settings below.</h4>
              <p class="info-box-subtext">Minimum 8 characters long, uppercase & symbol</p>
              <div class="form-group form-group-new group-password">
                <label for="new-password">Type or Generate New Password</label>
                <div class="d-flex flex-wrap col-gap-20 row-gap-20">
                  <div class="left flex-fill position-relative">
                    <input :type="showPassword ? 'text' : 'password'" id="new-password" v-model="form.newPassword"
                      class="form-control border-one h-60 pr-40" placeholder="Enter new password" required />
                    <span class="eye-icon-cont" @click="showPassword = !showPassword" style="cursor: pointer;">
                      <img class="icon-eye" :src="showPassword
                        ? 'https://serpplus.pluspromotions.co.uk/images/icon-eye-new.svg'
                        : 'https://serpplus.pluspromotions.co.uk/images/icon-eye-cross-new.svg'" alt="Toggle Password Visibility" />
                    </span>
                  </div>
                  <div class="right">
                    <button type="button" class="admin-common-btn h-60 p-20" @click="generateRandomPassword">
                      <img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-reload.svg"
                        alt="Icon Reload" />
                      Generate
                    </button>
                  </div>
                </div>
              </div>

              <!-- Checkbox to Send Password -->
              <div class="form-group">
                <div class="choose-plan-checkbox-cont d-inline-block">
                  <div class="form-check form-check-small d-flex align-items-center">
                    <label for="send-password" class="form-check-label">
                      Send the same password to the customer
                    </label>
                    <input id="send-password" type="checkbox" class="form-check-input"
                      v-model="form.sendPasswordToCustomer" />
                  </div>
                </div>
              </div>

              <!-- Submit Button -->
              <div class="form-group">
                <button type="button" class="admin-common-btn" @click="updateCustomer" :disabled="!form.newPassword">
                  Change Password
                </button>
              </div>
            </div>
          </div>

          <div class="profile-info-box common-border-new border-radius15 position-relative h-100 p-30 pt-20"
            v-if="activeTab == 'Billing & Plans'">
            <div class="info-box-cont">
              <!-- <h2 class="info-box-title">Current Plan</h2>
              <h4 class="info-box-subtitle text-dark-new">Current Plan is Basic <img class="icon-eye"
                  src="https://serpplus.pluspromotions.co.uk/images/icon-i.svg" alt="Icon i"></h4>
              <p class="info-box-subtext">A simple start for everyone</p>
              <h4 class="info-box-subtitle">Cost: <span class="text-dark-new fw-medium">10.00 euro/month</span></h4>
              <div class="day-remaining-cont">
                <p
                  class="day-remaining-top d-flex justify-content-between align-items-center col-gap-20 fw-medium mb-15">
                  <span>Active until Jan 17, 2025</span>
                  <span>26 of 30 Days</span>
                </p>
                <div class="progress common-progress mb-20" role="progressbar" aria-label="Basic example"
                  aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-bar w-50"></div>
                </div>
                <p class="text-start text-light-new">4 days remaining</p>
              </div>
              <a class="admin-common-btn btn-light-red mb-30" href="#">Cancel Subscription</a>
              <hr class="border-one mt-0 mb-15 opacity-100"> -->
              <div v-if="isUserPlanValid">
                <h2 class="info-box-title">Upgrade Plan for This Account</h2>
                <p class="info-box-subtext">Choose Your Payment Frequency</p>
                <div class="form-group form-group-new">
                  <div class="form-check form-check-inline custom-radio">
                    <input class="form-check-input" v-model="selectedInterval" type="radio" name="inlineRadioOptions"  @change="updateSelectedPlan()"
                      id="inlineRadio1" value="month" checked>
                    <label class="form-check-label" for="inlineRadio1">Monthly</label>
                  </div>
                  <div class="form-check form-check-inline custom-radio">
                    <input class="form-check-input" v-model="selectedInterval" type="radio" name="inlineRadioOptions"  @change="updateSelectedPlan()"
                      id="inlineRadio2" value="year">
                    <label class="form-check-label" for="inlineRadio2">Yearly</label>
                  </div>
                </div>
                <div class="form-group form-group-new">
                  <label>Choose Plan From Dropdown</label>
                  <select class="form-control h-60" v-model="selectedPlanName" @change="updateSelectedPlan()">
                    <option v-for="plan in plans" :key="plan.id" :value="plan.name">
                      {{ plan.name }} - ${{ selectedInterval === 'month' ? plan.monthly_price : plan.yearly_price }}/{{
                        selectedInterval }}
                    </option>
                  </select>
                </div>
                <div class="plan-details">
                  <p><strong>Current Plan is: <span class="text-primary-new">{{ selectedPlan.name || 'No active plan' }}</span></strong></p>
                  <p><span class="mr-10"><strong class="text-primary-new">Cost:</strong> ${{ selectedInterval === 'month' ? selectedPlan?.monthly_price :
                    selectedPlan?.yearly_price }}/{{ selectedInterval }}</span><span><strong class="text-primary-new">Credits:</strong>
                    {{ selectedInterval === 'month' ? selectedPlan?.monthly_credits : selectedPlan?.yearly_credits }}</span></p>                
                </div>
                <p class="info-box-subtext">Check the Pricing page for complete details on plans and inclusions. <a
                    class="text-decoration-none" href="javascript:void(0);">Click here to visit Pricing page</a></p>
                <div class="bottom-btn-cont d-flex flex-wrap justify-content-start col-gap-20 row-gap-20">
                  <button class="admin-common-btn d-flex justify-content-center align-items-center col-gap-10" href="#"
                    @click="changePlan()"
                    :disabled="userPlan.name == selectedPlanName && selectedInterval === userPlan?.interval || userPlan?.subscription?.is_cancelled === 1"><img
                      class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-crown-two.svg" alt="Icon Crown"> Upgrade
                    to {{ selectedInterval === 'month' ? 'Monthly' : 'Yearly' }} Plan</button>
                    <button class="admin-common-btn btn-light-red"
                    :class="{ 'cancelled': userPlan?.subscription?.is_cancelled === 1 }" v-if="isUserPlanValid"
                    @click="handleCancel" :disabled="userPlan?.subscription?.is_cancelled === 1">
                    {{ userPlan?.subscription?.is_cancelled === 1 ? 'Subscription Cancelled' : 'Cancel Subscription' }}
                  </button>
                </div>
              </div>
              <div v-else>
                <div class="message">
                  <p><strong>Oops!</strong> You need to purchase at least one plan to access the features. 🛒✨</p>
                  <p>Don't worry, we'll be adding the "Save" option for your card soon. Stay tuned! ⏳</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-add-question" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-911 modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title flex-column">
            <span class="mod-title">User Information</span>
            <p class="modal-sub-title d-block">Update user personal and credit information</p>
          </h5>
          <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Close">
          </a>
        </div>

          <div class="modal-body">
            <div class="modal-form-cont user-info-form-cont">
              <div class="form-group">
                <label>Full Name</label>
                <input class="form-control" type="text" v-model="form.name" placeholder="Enter Full Name" required>
              </div>
              <div class="custom-row-10 row">
                <div class="custom-col-10 col-12 col-md-6">
                  <div class="form-group">
                    <label>Email Address</label>
                    <input class="form-control" type="email" v-model="form.email" placeholder="Enter Full Email Address"
                      required>
                  </div>
                </div>
                <div class="custom-col-10 col-12 col-md-6">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input class="form-control" type="number" v-model="form.contact" placeholder="Enter Phone Number"
                      required>
                  </div>
                </div>
              </div>
              <div class="custom-row-10 row">
                <div class="custom-col-10 col-12 col-md-4">
                  <div class="form-group">
                    <label>Language</label>
                    <select class="form-control" required v-model="form.language">
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="fr">French</option>
                      <option value="de">German</option>
                      <option value="it">Italian</option>
                      <option value="pt">Portuguese</option>
                      <option value="ru">Russian</option>
                      <option value="ar">Arabic</option>
                      <option value="zh">Chinese</option>
                      <option value="ja">Japanese</option>
                      <option value="hi">Hindi</option>
                      <option value="ko">Korean</option>
                      <option value="bn">Bengali</option>
                      <option value="tr">Turkish</option>
                      <option value="pl">Polish</option>
                    </select>

                  </div>
                </div>
                <div class="custom-col-10 col-12 col-md-4">
                  <div class="form-group">
                    <label>Change Trial To Paid</label>
                    <select class="form-control" v-model="form.type">
              <option value="1">Trial</option>
              <option value="2">Paid</option>
            </select>
                  </div>
                </div>
                <div class="custom-col-10 col-12 col-md-4">
                  <div class="form-group">
                    <label>Active Status</label>
                 
                    <select class="form-control" v-model="form.suspend">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>

                  </div>
                </div>
              </div>
              <div class="custom-row-10 row">
                <div class="custom-col-10 col-12 col-md-4">
                  <div class="form-group">
                    <label>Credits</label>
                    <input class="form-control" v-model.number="form.credits" type="text" placeholder="Enter Credits" required>
                  </div>
                </div>
                
                
              </div>
            </div>
            <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
          </div>
          <div class="modal-footer col-gap-20 row-gap-10">
            <button class="admin-common-btn btn-light-red btn-cancel" type="button" data-bs-dismiss="modal"
              aria-label="Close">Cancel</button>
            <button class="admin-common-btn btn-save-changes" data-bs-dismiss="modal" type="submit" @click="updateCustomer">Save Changes</button>
          </div>

      </div>
    </div>
  </div>

  <CopyRight></copyRight>

</template>

<script setup>
import axiosIns from "@/axios";
import { ref, onMounted, computed } from "vue";
import CopyRight from '../copyRight.vue';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const router = useRouter();
const tabs = ["Purchase History", "Security", "Billing & Plans"];
const activeTab = ref("Purchase History");

const showProfilePopup = ref(false);
const showPlansPopup = ref(false);
const selectedInterval = ref('month');
const selectedPlanName = ref(null);
const selectedPlan = ref({
  name: '',
  monthly_price: 0,
  yearly_price: 0,
  monthly_credits: 0,
  yearly_credits: 0,
});

const remainingDays = computed(() => {
  const expiryDate = new Date(userPlan.value.subscription?.expiry_date);
  const currentDate = new Date();
  const timeDifference = expiryDate - currentDate;
  const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysRemaining > 0 ? daysRemaining : 0;
});

const progressWidth = computed(() => {
  const expiryDate = new Date(userPlan.value.subscription?.expiry_date);
  const purchaseDate = new Date(userPlan.value.subscription?.purchase_date);
  const totalDays = Math.ceil((expiryDate - purchaseDate) / (1000 * 3600 * 24));
  const daysPassed = Math.ceil((new Date() - purchaseDate) / (1000 * 3600 * 24));

  return (daysPassed / totalDays) * 100;
});
const isUserPlanValid = computed(() => {
  return userPlan.value && 
         Object.keys(userPlan.value).length > 1 && 
         !userPlan.value.is_expired_plan;
});


const form = ref({
  id: '',
  name: '',
  email: '',
  contact: '',
  language: '',
  credits: '',
  suspend: '',



  

  free: '',
  joined: '',
  type: '',
  newPassword: '',
  sendPasswordToCustomer: false,
});

const showPassword = ref(false);
const sub_id = ref(null);
const plans = ref([]);



const updateSelectedPlan = () => {
  const plan = plans.value.find((p) => p.name === selectedPlanName.value);

  if (plan) {
    selectedPlan.value = plan;
    selectedPlanName.value = plan.name;

    if (!selectedPlan.value.plans_stripe || !Array.isArray(selectedPlan.value.plans_stripe)) {
      selectedPlan.value.plans_stripe = plan.plans_stripe || [];
    }

  } else {
    console.error('Selected plan not found');
  }
};

const generateRandomPassword = () => {
  const randomPassword = Math.random().toString(36).slice(-8); // Generate a random 8-character password
  form.value.newPassword = randomPassword;
  toast.success('Random password generated!');
};

const fetchPlans = async () => {
  try {
    const response = await axiosIns.get('plans');
    plans.value = response.data.plans;

  } catch (error) {
    console.error('Error fetching plans:', error);
  }
}
const icons = [
  "https://serpplus.pluspromotions.co.uk/images/icon-purchase-history.svg",
  "https://serpplus.pluspromotions.co.uk/images/icon-security.svg",
  "https://serpplus.pluspromotions.co.uk/images/icon-billing-plan.png",
];
const changePlan = async () => {
  try {

    const confirmation = window.confirm(
      "Are you sure you want to upgrade or downgrade the customer's plan?"
    );

    if (!confirmation) {
      return;
    }
    const selectedPrice = selectedPlan.value.plans_stripe.find(
      (stripePlan) => stripePlan.interval === selectedInterval.value
    );

    if (selectedPrice) {
      const payload = {
        sub_id: sub_id.value,
        price_id: selectedPrice.price,
        user_id: router.currentRoute.value.params.id,
      };

      const response = await axiosIns.post('/plans/change-plan-from-admin', payload);

      toast.success('Plan successfully changed!', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
        theme: "colored",
      });

      await fetchCustomerData();

    } else {
      toast.error('Pricing interval not found. Please select a valid interval.', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
        theme: "colored",
      });
    }
  } catch (error) {
    console.error("Error changing plan:", error);
    toast.error('An error occurred while changing the plan. Please try again later.', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
      theme: "colored",
    });
  }
};


const userPlan = ref({});

const handleCancel = async () => {
  if (!confirm("Are you sure you want to cancel this subscription?")) {
    return;
  }

  try {
    await axiosIns.post(`/plans/cancel-subscription/${sub_id.value}/${router.currentRoute.value.params.id}`);
    toast.success("Subscription cancelled successfully. Please check your email for further instructions.", {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
    });
    await fetchCustomerData();
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    toast.error("An error occurred while cancelling the subscription. Please try again later.");
  }
};
const fetchCustomerData = async () => {
  try {
    const response = await axiosIns.get(`/user/show/${router.currentRoute.value.params.id}`);

    const userData = response?.data?.userData ?? {};
    const plan = response?.data?.plan ?? {};

    const formattedDate = userData?.created_at
      ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }).format(new Date(userData.created_at))
      : 'N/A';

    userPlan.value = plan;

    

    if (userPlan.value?.subscription) {
      const subscription = userPlan.value.subscription;
      const expiryDate = subscription?.expiry_date ? new Date(subscription.expiry_date) : null;
      const purchaseDate = subscription?.purchase_date ? new Date(subscription.purchase_date) : null;

      if (expiryDate && purchaseDate && !isNaN(expiryDate) && !isNaN(purchaseDate)) {
        const diffInMonths =
          (expiryDate.getFullYear() - purchaseDate.getFullYear()) * 12 +
          (expiryDate.getMonth() - purchaseDate.getMonth());
        selectedInterval.value = diffInMonths > 1 ? 'year' : 'month';
      } else {
        selectedInterval.value = 'month';
      }
    } else {
      selectedInterval.value = 'month';
    }

    userPlan.value.interval = selectedInterval.value;

    selectedPlan.value = {
      name: userPlan.value?.name ?? 'No active plan',
      monthly_price: userPlan.value?.monthly_price ?? 0,
      yearly_price: userPlan.value?.yearly_price ?? 0,
      monthly_credits: userPlan.value?.monthly_credits ?? 0,
      yearly_credits: userPlan.value?.yearly_credits ?? 0,

    };

    sub_id.value = userPlan.value?.subscription?.sub_id ?? null;
    selectedPlanName.value = selectedPlan.value?.name;
    console.log(selectedPlan.value);
    
    form.value = {
      id: userData?.id ?? null,
      name: userData?.name ?? '',
      email: userData?.email ?? '',
      contact: userData?.contact ?? '',
      language: userData?.language ?? '',
      credits: userData?.credits ?? 0,
      suspend: userData?.suspend ?? false,


      joined: formattedDate,

      
      type: userData?.type ?? '',
      country: userData?.country ?? '',
    };
  } catch (error) {
    console.error('Error fetching customer data:', error.message);
  }
};



const purchaseHistory = ref([]);
const fetchCustomerPlans = async () => {
  try {
    const userId = router.currentRoute.value.params.id;
    const response = await axiosIns.get(`plans/list-customer-plans?user_id=${userId}`);
    const customerPlan = response.data.regular_subscriptions.concat(response.data.top_up_subscriptions);
    customerPlan.sort((a, b) => {
      const dateA = new Date(a.purchase_date);
      const dateB = new Date(b.purchase_date);
      return dateB - dateA;
    });

    purchaseHistory.value = customerPlan;
  } catch (error) {
    console.error('Error fetching customer plans:', error);
    toast.error('Failed to fetch customer plans.', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
    });
  }
};

const updateCustomer = async () => {
  const payload = {
    name: form.value.name,
    email: form.value.email,
    contact: form.value.contact,
    language: form.value.language,
    credits: form.value.credits,
    suspend: form.value.suspend == 1 ? 0 : 1,
    type: form.value.type,
    newPassword: form.value.newPassword,
    sendPasswordToCustomer: form.value.sendPasswordToCustomer
  };

  try {
    await axiosIns.put(`/profile/update/${form.value.id}`, payload);

    let successMessage;

    if (form.value.suspend === 1) {
      successMessage = 'Profile updated. The customer has been suspended.';
    } else if (form.value.sendPasswordToCustomer) {
      successMessage = 'Profile updated. Password has been changed and mailed to the customer.';
    } else {
      successMessage = 'Profile updated successfully.';
    }

    toast.success(successMessage, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });

    showProfilePopup.value = false;
    setTimeout(async () => {
      await fetchCustomerData(router.currentRoute.value.params.id);
    }, 10000)
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });
  }
};


onMounted(async () => {
  await fetchCustomerData();
  await fetchCustomerPlans();
  await fetchPlans();

});
</script>

<style scoped>
.container {
  display: flex;
  gap: 16px;
  padding: 16px;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.right-section {
  flex: 2;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.btn {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  /* border-radius: 4px; */
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #565e64;
}

.btn-suspended {
  background-color: #ff4d4d;
  /* Light Red */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: not-allowed;
}

.btn-active {
  /* Light Green */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 16px;
}

.tab-button {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
}

.tab-button.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.form-group {
  /* margin-bottom: 16px; */
}

.form-group label {
  /* display: block;
  margin-bottom: 8px;
  font-weight: 600; */
}

.popup-actions {
  display: flex;
  gap: 8px;
}

.plans-table {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cancelled {
  color: #fff;
  background-color: #ff4d4d !important;
  border: none !important;
  padding: 0.6rem 1.2rem !important;
  border-radius: 5px !important;
  color: white !important;
  font-weight: bold !important;
  cursor: not-allowed !important;
  opacity: 0.8 !important;
}

.interval-toggle {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.interval-toggle label {
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.plan-dropdown {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.plan-details p {
  /* margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #555; */
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.upgrade-btn {
  background-color: #5a67d8;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.upgrade-btn:hover {
  background-color: #434190;
}

.cancel-btn {
  background-color: #e53e3e;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #c53030;
}


.plans-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.plans-info p {
  margin: 5px 0;
}

.progress-container {
  background-color: #f1f1f1;
  border-radius: 10px;
  height: 20px;
  width: 100%;
  margin: 15px 0;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
  transition: width 0.3s ease;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  border-radius: 5px;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
}

.cancel-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.settings-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.settings-description {
  font-size: 0.9rem;
  margin-bottom: 20px;
  color: #666;
}

.form-group {
  /* margin-bottom: 20px; */
}

.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-control {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.generate-btn {
  padding: 10px 15px;
  font-size: 0.9rem;
}

.checkbox-group {
  margin-top: 10px;
}

.form-check-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: #333;
}

.form-actions {
  margin-top: 20px;
}

.save-btn {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.save-btn:hover:enabled {
  background-color: #0056b3;
}
</style>