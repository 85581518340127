<template>

  <div class="domain-projects-page-cont" v-if="userData?.type === 2">
    <div class="domain-projects-table-cont">
      <div class="domain-projects-table-box">
        <div class="box-top box-top-with-filter-new justify-content-between">
          <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
            <router-link to="/domain-check" class="btn btn-light admin-common-btn me-4">
              <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg"
                alt="Check Serp Icon" />
              Check Domain Rank
            </router-link>
          </div>
          <div class="table-top-bar">
            <div class="top-left">
              <div class="form-group search-group">
                <input class="table-search-input" placeholder="Search Here" v-model="searchTerm"
                  @input="fetchProjects" />
              </div>
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="data-table-cont">
            <div class="table-responsive table-container table-normal-view table-domain-projects"
              @scroll="handleScroll">
              <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                <thead>
                  <tr>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Domain</span>
                          <img class="img-fluid th-icon"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle.svg"
                            alt="Question Icon" title="Domain" />
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Location</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Limit</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-left">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Date Created</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Open Project</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center" style="width: 67px">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Remove</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="project in allData" :key="project.id" class="no-wrap">
                    <td class="align-left">
                      <router-link :to="'/domain-view/' + project.id">{{
                        project.target
                        }}</router-link>
                    </td>
                    <td class="align-left">{{ project.location_name }}</td>
                    <td class="align-left">{{ project.limit }}</td>
                    <td class="align-left">{{ project.created_at }}</td>
                    <td class="align-center">
                      <p class="text-highlight">
                        <router-link :to="'/domain-view/' + project.id" class="text-primary">
                          Open
                          <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"
                            alt="Open Link Icon" title="Open" />
                        </router-link>
                      </p>
                    </td>
                    <td class="align-center">
                      <a class="table-action-btn-new bg-signal-red-light btn-remove" href="#"
                        @click="deleteProject(project)"><img class="icon-img img-fluid"
                          src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" /></a>
                    </td>
                  </tr>

                  <tr v-if="loading">
                    <td colspan="10" class="text-center">
                      <p style="font-size: 1.2em">⏳ Loading...</p>
                    </td>
                  </tr>

                  <tr v-else-if="!allData.length">
                    <td colspan="10" class="text-center">
                      <p style="font-size: 1.2em">🧐 No Data Available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="domain-projects-page-cont" v-else>

    <div class="common-top-cont text-center mb-60">
      <div class="width900 d-inline-block">
        <h1 class="common-top-title fs-40 fw-normal text-yellow-two mb-15">Domain Checker Module is Locked!</h1>
        <p class="common-top-p fs-20 lh-sm text-light-new mb-30">You don't have any active plan. Please upgrade to
          Analyse domain performance with detailed keyword rankings, traffic insights, and comparison reports.</p>
        <a class="admin-common-btn admin-common-btn-new-big yellow-two" href="#"><img class="img-fluid mr-10"
            src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> Upgrade Now & Unlock
          Fun Access!</a>
      </div>
    </div>

    <div class="common-text-img-box-cont">

      <div class="common-text-img-box">
        <div class="common-text-img-row row row-30">
          <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Keyword & Traffic Insights</h2>
            <p class="box-p fs-16 mb-20">Gain valuable insights into your website’s performance with comprehensive
              keyword and traffic data:</p>
            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
              <li><strong class="text-dark-new fw-medium">Number of Ranking Keywords:</strong> Track how many keywords
                your website ranks for in search engines.</li>
              <li><strong class="text-dark-new fw-medium">Monthly Traffic Estimation:</strong> Get an accurate
                estimate of your organic and paid traffic to understand audience reach and engagement.</li>
            </ul>
          </div>
          <div class="col-12 col-lg-5 img-col col-30">
            <div class="img-box">
              <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image1.jpg"
                alt="Box Image" />
            </div>
          </div>
        </div>
      </div>

      <div class="common-text-img-box">
        <div class="common-text-img-row row row-30">
          <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Performance Charts</h2>
            <p class="box-p fs-16 mb-20">Visualize your website’s success with intuitive graphs and charts:</p>
            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
              <li><strong class="text-dark-new fw-medium">Top Pages Analysis:</strong> See which pages drive the most
                traffic and contribute to your website’s growth.</li>
              <li><strong class="text-dark-new fw-medium">Paid vs. Organic Traffic:</strong> Compare traffic sources
                to refine your SEO and advertising strategies effectively.</li>
            </ul>
          </div>
          <div class="col-12 col-lg-5 img-col col-30">
            <div class="img-box">
              <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image2.jpg"
                alt="Box Image" />
            </div>
          </div>
        </div>
      </div>

      <div class="common-text-img-box">
        <div class="common-text-img-row row row-30">
          <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Detailed Data Table</h2>
            <p class="box-p fs-16 mb-20">Access a structured, in-depth view of your website’s keyword rankings and
              performance metrics:</p>
            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
              <li><strong class="text-dark-new fw-medium">Domain Position Tracking:</strong> Monitor your website’s
                position for targeted keywords over time.</li>
              <li><strong class="text-dark-new fw-medium">Keyword Insights:</strong> Get key details like search
                volume, keyword difficulty (KD), cost-per-click (CPC), last update date, and creation date to
                strategize better.</li>
            </ul>
          </div>
          <div class="col-12 col-lg-5 img-col col-30">
            <div class="img-box">
              <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image3.jpg"
                alt="Box Image" />
            </div>
          </div>
        </div>
      </div>

      <div class="common-text-img-box">
        <div class="common-text-img-row row row-30">
          <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Comparison Reports</h2>
            <p class="box-p fs-16 mb-20">Evaluate progress with historical ranking comparisons:</p>
            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
              <li><strong class="text-dark-new fw-medium">Today vs. Earlier Rankings:</strong> Analyze how your
                keyword rankings have changed over time, helping you track SEO improvements or drops.</li>
            </ul>
          </div>
          <div class="col-12 col-lg-5 img-col col-30">
            <div class="img-box">
              <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image4.jpg"
                alt="Box Image" />
            </div>
          </div>
        </div>
      </div>

      <div class="common-text-img-box">
        <div class="common-text-img-row row row-30">
          <div class="col-12 col-lg-7 text-col col-30 d-flex flex-wrap flex-column justify-content-center">
            <h2 class="box-title fs-20 fw-semibold text-primary-new mb-10">Data Export</h2>
            <p class="box-p fs-16 mb-20">Seamlessly export data for further analysis and offline access:</p>
            <ul class="box-ul fs-16 lh-1_844 text-light-new pl-15 mb-0">
              <li><strong class="text-dark-new fw-medium">Excel Download:</strong> Download keyword insights,
                rankings, and traffic reports in an easy-to-use Excel format for detailed review and sharing.</li>
            </ul>
          </div>
          <div class="col-12 col-lg-5 img-col col-30">
            <div class="img-box">
              <img class="img-fluid w-100" src="https://serpplus.pluspromotions.co.uk/images/text-img-box-image5.jpg"
                alt="Box Image" />
            </div>
          </div>
        </div>
      </div>

      <div class="upgrade-message-container upgrade-big w-100">
        <h2 class="upgrade-title">Purchase A Plan To Unlock This Feature</h2>
        <p class="upgrade-text text-light-new">You don't have any active plan. Please purchase a plan to take your SEO
          game to the next level!</p>
        <div class="upgrade-btn-cont">
          <router-link to='/pricing-page'
            class="upgrade-link admin-common-btn admin-common-btn-new-big yellow-two"><img class="img-fluid mr-10"
              src="https://serpplus.pluspromotions.co.uk/images/icon-crown.svg" alt="Icon Crown" /> View
            Planes</router-link>
        </div>
      </div>

    </div>
  </div>

  <CopyRight></copyRight>

</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import axiosIns from "@/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CopyRight from '../copyRight.vue';

const projects = ref([]);
const currentPage = ref(1);
const lastPage = ref(1);
const totalEntries = ref(0);
const searchTerm = ref("");
const loading = ref(false);
const allData = ref([]);

const handleScroll = (event) => {
  const container = event.target;

  if (container.scrollHeight > container.clientHeight) {
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      loadMore();
    }
  }
};

const loadMore = () => {
  if (currentPage.value >= lastPage.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPage.value++;
    fetchProjects();
  }, 1000);
};

const fetchProjects = async (isSearch = false) => {
  loading.value = true;

  try {
    const response = await axiosIns.get("/domain/list-domain-projects", {
      params: {
        page: currentPage.value,
        search: searchTerm.value,
      },
    });
    loading.value = false;

    if (isSearch) {
      allData.value = response.data.data.domainCheckers;
    } else {
      allData.value.push(...response.data.data.domainCheckers);
    }

    currentPage.value = response.data.data.pagination.current_page;
    lastPage.value = response.data.data.pagination.last_page;
    totalEntries.value = response.data.data.pagination.total;
  } catch (error) {
    loading.value = false;
    console.error("Error fetching projects:", error);
  }
};


const deleteProject = (project) => {
  const confirmation = confirm(
    `Are you sure you want to delete project ${project.target}?`
  );
  if (confirmation) {
    axiosIns
      .delete(`/domain/delete-domain-project/${project.id}`)
      .then((response) => {
        toast.success("Project deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000,
        });
        projects.value = projects.value.filter((p) => p.id !== project.id);
      })
      .catch((error) => {
        toast.error(`Failed to delete project: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000,
        });
      });
  }
};


watch(searchTerm, (newSearchTerm) => {
  if (newSearchTerm.trim() !== "") {
    fetchProjects(true);
  } else {
    fetchProjects();
  }
});
const userData = ref(null);
onMounted(() => {
  fetchProjects();
  const data = localStorage.getItem('userData');
  userData.value = JSON.parse(data);
});
</script>

<style scoped>
.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  padding-left: 2rem;
  /* Adjust this value as needed */
}

.search-icon {
  position: absolute;
  left: 0.5rem;
  /* Adjust this value as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevent the icon from blocking input clicks */
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  background-color: #fff;
}

.autocomplete-result {
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #f0f0f0;
}

.autocomplete-no-results {
  padding: 8px;
  color: #999;
}

input:disabled {
  color: #212529 !important;
  background-color: rgba(59, 59, 59, 0.1) !important;
  cursor: not-allowed;
}


.table-container {
  max-height: 1500px;
  overflow-y: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
