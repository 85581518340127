<template>

   

    <div class="common-inner-body credits-history-page-cont">
        <div class="common-inner-cont">
            <div class="box-body">
                <div class="data-table-cont">
                    <div 
                        class="table-responsive table-container table-normal-view"
                        @scroll="handleScroll"
                        ref="scrollContainer"
                    >
                        <table id="example" class="data-table table no-border dataTable no-footer">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap width30">Credits History</th>
                                    <th class="no-wrap width30">Date</th>
                                    <th class="no-wrap width40">Credits Use Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="creditsHistory.length === 0 && !loading">
        <td colspan="3" class="text-center">No credits history available.</td>
    </tr>
                                <tr v-for="(credit, index) in creditsHistory" :key="index">
                                    <td class="no-wrap text-start">
                                        <span class="td-box"
                                            :class="{ 'box-green': credit.is_added, 'box-red': !credit.is_added }">
                                            {{ credit.is_added ? '+ ' : '- ' }}{{ Math.round(credit.credits_used) }} cred
                                        </span>
                                    </td>
                                    <td class="no-wrap text-start responsive-wrap max-width110-mobile">
                                        {{ credit.date_time || 'N/A' }}
                                    </td>
                                    <td class="no-wrap text-start">
                                        {{ credit.title }}
                                    </td>
                                </tr>
                              
                            </tbody>
                        </table>
                        <p v-if="loading && creditsHistory.length > 0" class="text-center">Loading more...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <copyRight></copyRight>

</template>

<script>
import axiosIns from '@/axios';
import Loader from '../Loader.vue';
import copyRight from '../copyRight.vue';

export default {
    components: {
        Loader,
        copyRight
    },
    data() {
        return {
            creditsHistory: [],
            loading: false,
            currentPage: 1,
            lastPage: null,
            totalRecords: 0,
            perPage: 10
        };
    },
    methods: {
        async fetchCreditsHistory(page = 1) {
            if (this.loading) return;

            this.loading = true;
            try {
                const response = await axiosIns.get(`/user/history?page=${page}`);
                
                if (page === 1) {
                    this.creditsHistory = response.data.history;
                } else {
                    this.creditsHistory = [...this.creditsHistory, ...response.data.history];
                }

                this.totalRecords = response.data.total;
                this.perPage = response.data.per_page;
                this.lastPage = Math.ceil(this.totalRecords / this.perPage); // Dynamically set last page
            } catch (error) {
                console.error('Error fetching credits history:', error);
            }
            this.loading = false;
        },
        handleScroll(event) {
            if (this.loading || this.creditsHistory.length === 0) return false; // Stop unnecessary calls

            const container = event.target;
            if (container.scrollHeight > container.clientHeight) {
                if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
                    this.loadMore();
                }
            }
        },
        loadMore() {
            if (this.currentPage >= this.lastPage) {
                console.log("No more data to load.");
                return false;
            }

            this.currentPage++;
            this.fetchCreditsHistory(this.currentPage);
        },
        formatDetails(details) {
            return details.replace(/[()]/g, '').replace(/\s+/g, ' | ');
        }
    },
    beforeMount() {
        this.fetchCreditsHistory();
    }
};
</script>

<style scoped>
.table-container {
    max-height: 800px;
    overflow-y: auto;
}
</style>
