<template>
  <!-- <Loader :active="loading" message="Please wait..." /> -->
  <div class="common-inner-body drc-page-cont">
    <div class="common-inner-cont">

      <div class="search-page-top">
        <h2 class="top-title">Check Your Domain Rank</h2>
        <h2 class="top-subtitle">
          Enter your domain below to discover the latest numbers for
          <strong>Organic & Paid Traffic, Serps and more</strong>
        </h2>
        <div v-if="showTooltipLimit" class="tooltip-alert common-alert alert-small alert-danger">
          <img class="alert-icon img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-info2.svg" alt="Photo" />Specify the maximum number of search results you want to retrieve. (e.g., 50)
        </div>

        <div v-if="showTooltipDomain" class="tooltip-alert common-alert alert-small alert-danger">
          <img class="alert-icon img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-info2.svg" alt="Photo" />Enter the domain (e.g., example.com) to filter search results specific to this website.
        </div>

        <div v-if="showTooltipLocation" class="tooltip-alert common-alert alert-small alert-danger">
          <img class="alert-icon img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-info2.svg" alt="Photo" />Select or enter a country/region to get search results specific to that location.
        </div>

        <div v-if="form.suggestions" class="tooltip-alert common-alert alert-small alert-info">
          <img class="alert-icon img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bulb.svg" alt="Photo" />{{ form.suggestions }}
        </div>

        <div class="top-form-cont">
          <div class="form-fld-cont domain-check-search flex-column row-gap-20@@@@">
            <div class="top-flds d-flex flex-wrap justify-content-center col-gap-20 row-gap-20@@@@ max-w-100">
              <div class="search-fld-cont domain-cont" :class="{ 'is-invalid': validationErrors.target }">
                <div class="input-fld-cont">
                  <input v-model="form.target" class="form-control domain" type="text" placeholder="Domain"
                    @focus="showTooltipDomain = true" @blur="showTooltipDomain = false" @input="validateTarget" />
                </div>
              </div>
              <div class="search-fld-cont keyword-cont">
                <div class="input-fld-cont">
                  <input v-model="form.limit" class="form-control keyword" type="text" placeholder="Limit (e.g., 50)"
                    @focus="showTooltipLimit = true" @blur="showTooltipLimit = false" @input="validateLimit" />
                </div>

              </div>

            </div>
            <div class="bottom-flds d-flex flex-wrap justify-content-center col-gap-20 row-gap-20@@@@ max-w-100">
              <div class="search-fld-cont country-cont search-country" :class="{ 'is-invalid': validationErrors.country }">
                <div class="input-fld-cont">
                  <select class="form-control country@@@@"
                   v-model="form.country"
                   @change="onCountryChange"
                   @focus="isCountryActive = true"
                   @blur="handleCountryBlur"
                   @keydown.down.prevent="navigateDropdown(1)"
                   @keydown.up.prevent="navigateDropdown(-1)"
                   @keydown.enter.prevent="selectDropdownItem" 
                   >
                    <option v-for="country in regionList" :key="country.country_iso_code" :value="country">
                      {{ country.location_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="search-fld-cont location-cont" :class="{ 'is-invalid': validationErrors.location }">
                <div class="input-fld-cont position-relative">
                  <input class="form-control location"
                  v-model="searchQuery" 
                  @input="onChange"  
                  type="text"
                  @keydown.down.prevent="navigateResults(1)"
                  @keydown.up.prevent="navigateResults(-1)"
                  @keydown.enter.prevent="selectResult(searchResults[resultIndex])"
                  placeholder="Enter State (e.g., Florida) and City (e.g., Miami)..."
/>

                  <!-- Autocomplete Results -->
                  <ul v-show="showResults" class="autocomplete-results">
                    <li v-for="(result, index) in searchResults" :key="index" 
                    class="autocomplete-result" 
                     :class="{ 'result-active': index === resultIndex }"
                      @click="selectResult(result)"  
                      @mouseover="hoverResult(index)" 
                     >
                      {{ result.location_name }}
                    </li>
                    <li v-if="
                      searchResults?.length === 0 && searchQuery.trim() !== ''
                    " class="autocomplete-no-results">
                      No results found
                    </li>
                  </ul>
                </div>
              </div>



              <div class="search-fld-cont btn-cont">
                <button @click="checkNow" class="admin-common-btn btn-search" :disabled="loading || !isFormValid">
                  <span v-if="loading" class="spinner"></span>
                  <span v-else>Check</span>
                </button>
              </div>
            </div>
          </div>

          <!-- <div v-if="form.suggestions" class="suggestion-box" style="margin-bottom: 5px !important;">
            <p>💡 {{ form.suggestions }}</p>
          </div> -->

          <!-- <p v-if="loading || !isFormValid" class="tooltip-alert common-alert alert-small alert-danger">
            <img class="alert-icon img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-info2.svg" alt="Photo" />Please fill above all the inputs to enable the button.
          </p> -->

          <!-- Validation Error Messages -->
          <div class="validation-messages">
            <p v-if="validationErrors.target" class="error-message">
              {{ validationErrors.target }}
            </p>
            <p v-if="validationErrors.limit" class="error-message">
              {{ validationErrors.limit }}
            </p>
            <p v-if="validationErrors.country" class="error-message">
              {{ validationErrors.country }}
            </p>
          </div>
        </div>
        <div class="search-page-img-cont">
          <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/domain-search-page-bg.png"
            alt="Photo" />
        </div>
      </div>
    </div>
  </div>

  <copyRight></copyRight>

</template>

<script setup>
import { ref, onMounted, computed, reactive, watch } from "vue";
import copyRight from '../copyRight';
import CountryService from "@/service/CountryService";
import axiosIns from "@/axios";
import Loader from "../Loader.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";
import EventBus from "@/EventBus";

const loading = ref(false);
const showUpgradeMessage = ref(false);

const form = reactive({
  target: "",
  language: "en",
  load_rank_absolute: true,
  limit: "",
  country: {
    "location_code": "2372",
    "location_name": "Ireland",
    "country_iso_code": "IE"
  },
  state_city: "",
});

const countryService = new CountryService();
const searchQuery = ref("");
const showResults = ref(false);
const regionList = ref(null);
const cityStateData = ref([]);
const pageSize = 20;
const currentPage = ref(1);
const router = useRouter();
const userType = ref(null);

const validationErrors = reactive({
  target: "",
  limit: "",
  country: "",
});

const isFormValid = computed(() => {
  return (
    !validationErrors.target &&
    !validationErrors.limit &&
    !validationErrors.country &&
    form.target &&
    form.limit &&
    form.country
  );
});

const onChange = () => {
    if (searchQuery.value.trim() !== "") {
        showResults.value = true;
    } else {
        showResults.value = false;
    }
};


function validateTarget() {
  if (!form.target) {
    validationErrors.target = "Domain is required.";
  } else if (
    !/^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/.test(
      form.target
    )
  ) {
    validationErrors.target =
      "Please enter a valid domain (e.g., example.com). 'www' is not allowed.";
  } else {
    validationErrors.target = "";
  }
}
const showTooltipLimit = ref(false);
const showTooltipDomain = ref(false);
const showTooltipLocation = ref(false);

function validateLimit() {
  if (!form.limit) {
    validationErrors.limit = "Keyword limit is required.";
  } else if (isNaN(form.limit)) {
    validationErrors.limit = "Keyword limit must be a number.";
  } 
  // else if (userType.value === 1 && form.limit > 50) {
  //   validationErrors.limit = "Limit for your account type is 50.";
  // } 
  else {
    validationErrors.limit = "";
  }
}

const onCountryChange = async () => {
  currentPage.value = 1;
  await fetchCityStateData();
};
const finalCreditsToCut = ref(0);
const checkNow = async () => {
  validateTarget();
  validateLimit();

  if (isFormValid.value) {
    loading.value = true;

    const logCreditsHistory = {
        moduleType: 2, 
        title: form.target + ' - ' + '( Domain Checker )', 
        creditsUsed: finalCreditsToCut.value 
    };

    const payload = {
      target: form.target,
      language: "en",
      load_rank_absolute: true,
      limit: form.limit,
      country: form.country ? Number(form.country.location_code) : null,
      state_city: form.state_city ? form.state_city.location_code : null,
      location_name: form.state_city ? form.state_city.location_name : null,
      option_location: form.country ? form.country.location_name : null,
      location_code: form.country ? form.country.location_code : null,
      logCreditsHistory: logCreditsHistory
    };

    try {
      const response = await axiosIns.post("/domain/check-domain", payload);
      loading.value = false;
      EventBus.$emit("loadCredits", true);
      if (response.data.data && response.data.data.domain_project_id) {
        router.push(`domain-view/${response.data.data.domain_project_id}`);
      }
      if (response && response.data && response.data.message) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000,
        });
      }
    } catch (error) {
      loading.value = false;
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch data. Please try again later.";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      });
    }
  }
};

const fetchCityStateData = async () => {
  try {
    const response = await countryService.getCityAndState(
      form.country.country_iso_code,
      currentPage.value,
      pageSize
    );
    cityStateData.value =
      currentPage.value === 1
        ? response
        : [...cityStateData.value, ...response];
  } catch (error) {
    console.error("Error fetching city data:", error);
  }
};

const searchResults = computed(() => {
  return searchQuery.value.trim()
    ? filteredLocations.value.filter((location) =>
      location.location_name
        .toLowerCase()
        .includes(searchQuery.value.trim().toLowerCase())
    )
    : [];
});

const filteredLocations = computed(() => {
  return searchQuery.value
    ? cityStateData.value.filter((location) =>
      location.location_name
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase())
    )
    : cityStateData.value;
});

onMounted(() => {
  const data = localStorage.getItem("userData");
  fetchCityStateData()

  if (data) {
    const parsedData = JSON.parse(data);

    userType.value = parsedData.type ?? 1;

    const storedModule = localStorage.getItem('module');
    if (storedModule) {
      try {
        module.value = JSON.parse(storedModule);
        serpModule.value = module.value[1];
      } catch (error) {
        console.error("Error parsing module data:", error);
      }
    } else {
      console.warn("No module data found in localStorage.");
    }

  }

  countryService.getCountries().then((data) => {
    regionList.value = data.sort((a, b) =>
      a.location_name.localeCompare(b.location_name)
    );
  });
});

function selectResult(result) {
  searchQuery.value = result.location_name;
  showResults.value = false;
  form.state_city = result;
}

const module = ref([]);
const serpModule = ref(null);


const dropdownIndex = ref(-1);
const resultIndex = ref(-1);
const navigateDropdown = (direction) => {
    if (!regionList.value.length) return;
    dropdownIndex.value = (dropdownIndex.value + direction + regionList.value.length) % regionList.value.length;
};

const selectDropdownItem = () => {
    if (dropdownIndex.value >= 0) {
        formData.value.country = regionList.value[dropdownIndex.value];
        dropdownIndex.value = -1;
    }
};

const navigateResults = (direction) => {
    if (!searchResults.value.length) return;
    resultIndex.value = (resultIndex.value + direction + searchResults.value.length) % searchResults.value.length;
};

const hoverResult = (index) => {
    resultIndex.value = index;
};



const generateSuggestions = (keywordCount) => {
    if (!serpModule.value) return '';

    const minKeywords = serpModule.value.transactions;
    const costPerCredit = serpModule.value.costPerCredit; 
    const taskCost = serpModule.value.taskCost;
    const keywordCost = serpModule.value.keywordCost; 

    const costForTransaction = (keywordCount * keywordCost) + taskCost;

    const requiredCredits = costForTransaction / costPerCredit;
    finalCreditsToCut.value = requiredCredits;
    if (keywordCount < minKeywords) {
        const remaining = minKeywords - keywordCount;
        return `You need to add at least ${minKeywords} keywords to start the process. (${remaining} more needed).  
        Current credits required: ${requiredCredits.toFixed(2)} credits.`;
    } else {
        return `Total credits required: ${requiredCredits.toFixed(2)} credits.`;
    }
};

// Watching form.limit for changes and updating suggestions dynamically
watch(() => form.limit, (newLength) => {
    console.log(serpModule.value);
    if (serpModule.value) {
        form.suggestions = generateSuggestions(newLength);
    }
}, { immediate: true });



watch(() => searchQuery.value, (newQuery) => {

if (!newQuery || newQuery.trim() === "") {
showResults.value = false;
}
});
watch(
  () => form.limit,
  (newValue) => {
    if (userType.value === 1 && newValue > 50) {
      form.limit = 50;
    }
  }
);

</script>

<style scoped>
.validation-messages .error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.tooltip-alert {
    background-color: #f8d7da;
    color: #721c24;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.upgrade-message-container {
  /* display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 1rem; */
}

.message-text {
  /* font-size: 0.95rem;
  color: #555; */
}

.upgrade-link {
  /* text-decoration: none;
  color: #45C9F4; */
}
</style>
<style scoped>
/* Hover Effect */
.autocomplete-result:hover,
.result-active {
    background-color: #f0f0f0;
    cursor: pointer;
}

/* Dropdown active selection */
.dropdown-active {
    background-color: #f0f0f0;
}
</style>